import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

const MQModalBody: FC<HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div
    data-testid="mq-modal-body"
    aria-label="Modal Body"
    className={classNames('mq-modal-body', className)}
    {...props}
    id="modal-body"
  />
);

export default MQModalBody;
