import {
  activityLogSlice,
  adminMatchCandidatesFiltersSlice,
  adminMatchCandidatesSlice,
  adminMatchGroupCandidatesFiltersSlice,
  adminMatchGroupCandidatesSlice,
  adminMatchGroupCartSlice,
  adminMatchParticipantsFiltersSlice,
  adminMatchParticipantsSlice,
  advancedFiltersSlice,
  auroraQuickcliQsReportSlice,
  authSlice,
  azureAuthorizationSlice,
  brandingFilesSlice,
  brandingSlice,
  bulkMatchSlice,
  calendarConfigurationSlice,
  calendarScheduleAvailabilitySlice,
  calendarScheduleSlice,
  chatSlice,
  cliqAiSlice,
  cliqipediaSlice,
  collaborationToolsProfileFieldsSlice,
  collaborationToolsSlice,
  communicationMatchSlice,
  communicationParticipantSlice,
  contentHelpdeskActionsExportSlice,
  contentHelpdeskReportSlice,
  contentHelpdeskSlice,
  dashboardMentoringActivitySlice,
  dashboardParticipantCountSlice,
  dashboardTopicsSlice,
  dependencySettingsSlice,
  developmentReportsSlice,
  draftProgramsSlice,
  emailSlice,
  enrollmentApprovalRequestSlice,
  enrollmentApprovalsSlice,
  enrollmentEncryptedPreferencesSlice,
  enrollmentPreferencesSlice,
  enrollmentSlice,
  enrollmentStateSlice,
  filesSlice,
  gdprConsentsSlice,
  gdprLatestConsentSlice,
  generatedReportInfoSlice,
  goalsProgressReportSlice,
  goalsSlice,
  googleAuthorizationSlice,
  groupWithoutGoalsSlice,
  hrisAccessIntegrationSlice,
  hrisAdditionalSlice,
  hrisConfigurationNoticeSlice,
  hrisConfigurationsSlice,
  hrisConfigurationUniqueIdentifierSlice,
  hrisSyncFileInvalidRecordSlice,
  hrisSyncSlice,
  idMappingsSlice,
  incompleteGoalsSlice,
  intelligentMatchingPastSlice,
  intelligentMatchingSlice,
  intlSlice,
  invitationRequestsSlice,
  invitationsSlice,
  invitedProgramsSlice,
  learningLabActionsSlice,
  learningLabCalendarEventsSlice,
  learningLabDetailedActionsReportSlice,
  learningLabEventsSlice,
  learningLabLiveSlice,
  learningLabSettingsSlice,
  linkedInSlice,
  linksSlice,
  locationSlice,
  manageMatchesSlice,
  manageParticipantsSlice,
  managePlatformResourcesSlice,
  matchCriteriaSlice,
  matchesInfoSlice,
  matchesSlice,
  matchRulesSlice,
  matchScoreDetailsSlice,
  mentorFlixReportsSlice,
  mentorFlixSlice,
  mentorFlixSuggestTopicSlice,
  mentoringActivityReportSlice,
  mentoringFocusSlice,
  mentoringTimeSlice,
  messagePreviewSlice,
  messageTemplatesSlice,
  milestoneProgressReportSlice,
  milestonesReportSlice,
  milestonesSlice,
  myCliQSlice,
  nudgeLeaveRelationshipSlice,
  participationReportConfigSlice,
  passwordSlice,
  peopleChangeRolesSlice,
  peopleCheckUpdateAccessSlice,
  peopleFiltersSlice,
  peopleSlice,
  peopleTrackingReportsSlice,
  personalInfoSlice,
  platformAdminUserAccountReportSlice,
  platformConfigurationSlice,
  platformInformationPrivateSlice,
  platformInformationPublicSlice,
  platformRelationshipExceptionSlice,
  platformSettingsAddOnsSlice,
  possibleValuesHRISFieldSlice,
  predefinedLeaveReasonsSlice,
  predefinedRetireReasonsSlice,
  predefinedUnmatchReasonsSlice,
  profileDisplayConfigSlice,
  profileSlice,
  profileStateSlice,
  profiletteSlice,
  profileTypeDependentSettingsSlice,
  profileTypeQuestionsSlice,
  programAdminRolesSlice,
  programAdminsSlice,
  programCategoriesSlice,
  programLogsSlice,
  programNoteSlice,
  programParticipationReportSlice,
  programResourcesFilterRolesSlice,
  programSatisfactionReportSlice,
  programSatisfactionSurveyInactivitySlice,
  programSatisfactionSurveySlice,
  programsBriefSlice,
  programSponsorsSlice,
  programsSlice,
  programSurveyConfigSlice,
  programTemplatesSlice,
  programUsedRolesSlice,
  programUserAccountReportSlice,
  promptEnrollmentsSlice,
  promptProfilesSlice,
  quickcliQsManualSendSlice,
  quickcliQsReportSlice,
  quickcliQsRequestsSlice,
  recipientsSlice,
  recipientsSummarySlice,
  relationshipExceptionSlice,
  relationshipGoalsSlice,
  relationshipSatisfactionReportSlice,
  relationshipSatisfactionSurveyInactivitySlice,
  relationshipSatisfactionSurveySlice,
  relationshipSurveyConfigSlice,
  reportFilterQuestionsSlice,
  reportFiltersSlice,
  reportsExportSlice,
  resetStateSlice,
  resourceLibraryItemsSlice,
  resourceLibraryReportsSlice,
  resourceLibrarySectionsSlice,
  roiBoostPopulationSlice,
  roiBoostProfileFieldSummarySlice,
  roiMetricsSlice,
  roiReportActionsExportSlice,
  roiReportActionsSlice,
  roiReportExpertsSlice,
  satisfactionSurveysConfigsSlice,
  scheduledCommunicationConfigsWithSentMessagesSlice,
  scheduledCommunicationSentMessagesSlice,
  scheduledCommunicationSlice,
  scheduledMessagePreviewSlice,
  securityRolesSlice,
  selfMatchCandidatesSlice,
  selfMatchFiltersSlice,
  selfMatchRequestSlice,
  skippedParticipantsSlice,
  slackBotSlice,
  smartMatchSlice,
  smartMatchTaskInfoSlice,
  ssoConfigurationSlice,
  ssoLoggingSlice,
  ssoLogSlice,
  substitutedPublishMessageSlice,
  suggestionCandidatesSlice,
  suggestionCartSlice,
  suggestionFiltersSlice,
  supervisorDetailsSlice,
  surveyBuilderQuestionsSlice,
  surveyBuilderReportsSlice,
  surveyBuilderSlice,
  surveyRequestsSlice,
  surveyTypesConfigSlice,
  switchUserCandidatesSlice,
  testSSOConfigurationSlice,
  timelineFiltersSlice,
  timelineSlice,
  timelineSpecsSlice,
  timeZonesSlice,
  userBasicInfoSlice,
  userOpenProgramsSlice,
  userSlice,
  vpsQuestionsSlice,
  zoomSlice,
  reportBuilderReportsListSlice,
  reportBuilderConfigSlice,
  reportBuilderModulesAndFieldsSlice,
  reportBuilderDataSlice,
} from '@mentorcliq/storage';
import { combineReducers } from '@reduxjs/toolkit';

export const combinedReducer = combineReducers({
  idMappings: idMappingsSlice.reducer,
  collaborationToolsProfileFields: collaborationToolsProfileFieldsSlice.reducer,
  collaborationTools: collaborationToolsSlice.reducer,
  learningLabActions: learningLabActionsSlice.reducer,
  learningLabDetailedActionsReport: learningLabDetailedActionsReportSlice.reducer,
  learningLabEvents: learningLabEventsSlice.reducer,
  learningLabSettings: learningLabSettingsSlice.reducer,
  learningLabCalendarEvents: learningLabCalendarEventsSlice.reducer,
  learningLabLive: learningLabLiveSlice.reducer,
  platformInformationPublic: platformInformationPublicSlice.reducer,
  platformInformationPrivate: platformInformationPrivateSlice.reducer,
  platformSettingsAddOns: platformSettingsAddOnsSlice.reducer,
  chat: chatSlice.reducer,
  intl: intlSlice.reducer,
  auth: authSlice.reducer,
  user: userSlice.reducer,
  switchUserCandidates: switchUserCandidatesSlice.reducer,
  myCliQ: myCliQSlice.reducer,
  manageParticipants: manageParticipantsSlice.reducer,
  dependencySettings: dependencySettingsSlice.reducer,
  programsBrief: programsBriefSlice.reducer,
  userOpenPrograms: userOpenProgramsSlice.reducer,
  invitedPrograms: invitedProgramsSlice.reducer,
  programCategories: programCategoriesSlice.reducer,
  programTemplates: programTemplatesSlice.reducer,
  enrollmentPreferences: enrollmentPreferencesSlice.reducer,
  enrollmentApprovals: enrollmentApprovalsSlice.reducer,
  enrollmentApprovalRequest: enrollmentApprovalRequestSlice.reducer,
  supervisorDetails: supervisorDetailsSlice.reducer,
  programs: programsSlice.reducer,
  promptProfiles: promptProfilesSlice.reducer,
  promptEnrollments: promptEnrollmentsSlice.reducer,
  draftPrograms: draftProgramsSlice.reducer,
  matchRules: matchRulesSlice.reducer,
  goals: goalsSlice.reducer,
  profileTypeQuestions: profileTypeQuestionsSlice.reducer,
  profileTypeDependentSettings: profileTypeDependentSettingsSlice.reducer,
  profile: profileSlice.reducer,
  profilette: profiletteSlice.reducer,
  profileState: profileStateSlice.reducer,
  calendarConfiguration: calendarConfigurationSlice.reducer,
  calendarSchedule: calendarScheduleSlice.reducer,
  calendarScheduleAvailability: calendarScheduleAvailabilitySlice.reducer,
  azureAuthorization: azureAuthorizationSlice.reducer,
  googleAuthorization: googleAuthorizationSlice.reducer,
  enrollmentState: enrollmentStateSlice.reducer,
  profileDisplayConfig: profileDisplayConfigSlice.reducer,
  vpsQuestions: vpsQuestionsSlice.reducer,
  gdprConsents: gdprConsentsSlice.reducer,
  milestones: milestonesSlice.reducer,
  matches: matchesSlice.reducer,
  matchesInfo: matchesInfoSlice.reducer,
  messageTemplates: messageTemplatesSlice.reducer,
  invitations: invitationsSlice.reducer,
  invitationRequests: invitationRequestsSlice.reducer,
  files: filesSlice.reducer,
  links: linksSlice.reducer,
  quickcliQsReport: quickcliQsReportSlice.reducer,
  milestonesReport: milestonesReportSlice.reducer,
  quickcliQsRequests: quickcliQsRequestsSlice.reducer,
  quickcliQsManualSend: quickcliQsManualSendSlice.reducer,
  suggestionCart: suggestionCartSlice.reducer,
  suggestionCandidates: suggestionCandidatesSlice.reducer,
  suggestionFilters: suggestionFiltersSlice.reducer,
  developmentReports: developmentReportsSlice.reducer,
  reports: developmentReportsSlice.reducer,
  relationshipSatisfactionSurvey: relationshipSatisfactionSurveySlice.reducer,
  relationshipSatisfactionSurveyInactivity: relationshipSatisfactionSurveyInactivitySlice.reducer,
  programSatisfactionSurvey: programSatisfactionSurveySlice.reducer,
  programSatisfactionSurveyInactivity: programSatisfactionSurveyInactivitySlice.reducer,
  selfMatchRequest: selfMatchRequestSlice.reducer,
  selfMatchCandidates: selfMatchCandidatesSlice.reducer,
  selfMatchFilters: selfMatchFiltersSlice.reducer,
  enrollment: enrollmentSlice.reducer,
  branding: brandingSlice.reducer,
  brandingFiles: brandingFilesSlice.reducer,
  adminMatchCandidates: adminMatchCandidatesSlice.reducer,
  adminMatchCandidatesFilters: adminMatchCandidatesFiltersSlice.reducer,
  adminMatchGroupCandidates: adminMatchGroupCandidatesSlice.reducer,
  adminMatchGroupCandidatesFilters: adminMatchGroupCandidatesFiltersSlice.reducer,
  adminMatchParticipants: adminMatchParticipantsSlice.reducer,
  adminMatchParticipantsFilters: adminMatchParticipantsFiltersSlice.reducer,
  adminMatchGroupCart: adminMatchGroupCartSlice.reducer,
  manageMatches: manageMatchesSlice.reducer,
  matchScoreDetails: matchScoreDetailsSlice.reducer,
  predefinedRetireReasons: predefinedRetireReasonsSlice.reducer,
  predefinedLeaveReasons: predefinedLeaveReasonsSlice.reducer,
  predefinedUnmatchReasons: predefinedUnmatchReasonsSlice.reducer,
  linkedin: linkedInSlice.reducer,
  matchCriteria: matchCriteriaSlice.reducer,
  nudgeLeaveRelationship: nudgeLeaveRelationshipSlice.reducer,
  platformConfiguration: platformConfigurationSlice.reducer,
  securityRoles: securityRolesSlice.reducer,
  programAdminRoles: programAdminRolesSlice.reducer,
  timeZones: timeZonesSlice.reducer,
  relationshipGoals: relationshipGoalsSlice.reducer,
  groupWithoutGoals: groupWithoutGoalsSlice.reducer,
  substitutedPublishMessage: substitutedPublishMessageSlice.reducer,
  gdprLatestConsent: gdprLatestConsentSlice.reducer,
  mentorFlixReports: mentorFlixReportsSlice.reducer,
  mentorFlixSuggestTopic: mentorFlixSuggestTopicSlice.reducer,
  programNotes: programNoteSlice.reducer,
  incompleteGoals: incompleteGoalsSlice.reducer,
  smartMatch: smartMatchSlice.reducer,
  email: emailSlice.reducer,
  location: locationSlice.reducer,
  mentorFlix: mentorFlixSlice.reducer,
  resetState: resetStateSlice.reducer,
  password: passwordSlice.reducer,
  relationshipSatisfactionReport: relationshipSatisfactionReportSlice.reducer,
  programSatisfactionReport: programSatisfactionReportSlice.reducer,
  dashboardParticipantCount: dashboardParticipantCountSlice.reducer,
  dashboardMentoringActivity: dashboardMentoringActivitySlice.reducer,
  dashboardTopics: dashboardTopicsSlice.reducer,
  reportFilterQuestions: reportFilterQuestionsSlice.reducer,
  relationshipException: relationshipExceptionSlice.reducer,
  reportFilters: reportFiltersSlice.reducer,
  participationReportConfig: participationReportConfigSlice.reducer,
  people: peopleSlice.reducer,
  peopleFilters: peopleFiltersSlice.reducer,
  peopleChangeRoles: peopleChangeRolesSlice.reducer,
  userBasicInfo: userBasicInfoSlice.reducer,
  goalsProgressReport: goalsProgressReportSlice.reducer,
  mentoringActivityReport: mentoringActivityReportSlice.reducer,
  milestoneProgressReport: milestoneProgressReportSlice.reducer,
  skippedParticipants: skippedParticipantsSlice.reducer,
  mentoringTime: mentoringTimeSlice.reducer,
  mentoringFocus: mentoringFocusSlice.reducer,
  programUserAccountReport: programUserAccountReportSlice.reducer,
  platformAdminUserAccountReport: platformAdminUserAccountReportSlice.reducer,
  programParticipationReport: programParticipationReportSlice.reducer,
  communicationParticipant: communicationParticipantSlice.reducer,
  communicationMatch: communicationMatchSlice.reducer,
  recipientsSummary: recipientsSummarySlice.reducer,
  roiBoostProfileFieldSummary: roiBoostProfileFieldSummarySlice.reducer,
  roiBoostPopulation: roiBoostPopulationSlice.reducer,
  roiReportExperts: roiReportExpertsSlice.reducer,
  roiMetrics: roiMetricsSlice.reducer,
  roiReportActions: roiReportActionsSlice.reducer,
  roiReportActionsExport: roiReportActionsExportSlice.reducer,
  recipients: recipientsSlice.reducer,
  messagePreview: messagePreviewSlice.reducer,
  enrollmentEncryptedPreferences: enrollmentEncryptedPreferencesSlice.reducer,
  managePlatformResources: managePlatformResourcesSlice.reducer,
  resourceLibrarySections: resourceLibrarySectionsSlice.reducer,
  resourceLibraryReports: resourceLibraryReportsSlice.reducer,
  resourceLibraryItems: resourceLibraryItemsSlice.reducer,
  reportsExport: reportsExportSlice.reducer,
  contentHelpdesk: contentHelpdeskSlice.reducer,
  contentHelpdeskReport: contentHelpdeskReportSlice.reducer,
  contentHelpdeskActionsExport: contentHelpdeskActionsExportSlice.reducer,
  timeline: timelineSlice.reducer,
  timelineFilters: timelineFiltersSlice.reducer,
  timelineSpecs: timelineSpecsSlice.reducer,
  activityLog: activityLogSlice.reducer,
  programUsedRoles: programUsedRolesSlice.reducer,
  cliqipedia: cliqipediaSlice.reducer,
  intelligentMatching: intelligentMatchingSlice.reducer,
  intelligentMatchingPast: intelligentMatchingPastSlice.reducer,
  smartMatchTaskInfo: smartMatchTaskInfoSlice.reducer,
  scheduledMessagePreview: scheduledMessagePreviewSlice.reducer,
  scheduledCommunication: scheduledCommunicationSlice.reducer,
  scheduledCommunicationSentMessages: scheduledCommunicationSentMessagesSlice.reducer,
  scheduledCommunicationConfigsWithSentMessages: scheduledCommunicationConfigsWithSentMessagesSlice.reducer,
  satisfactionSurveysConfigs: satisfactionSurveysConfigsSlice.reducer,
  bulkMatch: bulkMatchSlice.reducer,
  programSurveyConfig: programSurveyConfigSlice.reducer,
  relationshipSurveyConfig: relationshipSurveyConfigSlice.reducer,
  surveyRequests: surveyRequestsSlice.reducer,
  surveyBuilder: surveyBuilderSlice.reducer,
  surveyBuilderReports: surveyBuilderReportsSlice.reducer,
  surveyBuilderQuestions: surveyBuilderQuestionsSlice.reducer,
  surveyTypesConfig: surveyTypesConfigSlice.reducer,
  ssoConfiguration: ssoConfigurationSlice.reducer,
  testSSOConfiguration: testSSOConfigurationSlice.reducer,
  ssoLog: ssoLogSlice.reducer,
  ssoLogging: ssoLoggingSlice.reducer,
  programResourcesFilterRoles: programResourcesFilterRolesSlice.reducer,
  zoom: zoomSlice.reducer,
  platformRelationshipException: platformRelationshipExceptionSlice.reducer,
  hrisConfigurations: hrisConfigurationsSlice.reducer,
  hrisSync: hrisSyncSlice.reducer,
  hrisAdditional: hrisAdditionalSlice.reducer,
  hrisInvalid: hrisSyncFileInvalidRecordSlice.reducer,
  hrisIntegration: hrisAccessIntegrationSlice.reducer,
  hrisConfigurationUnique: hrisConfigurationUniqueIdentifierSlice.reducer,
  hrisConfigurationNotice: hrisConfigurationNoticeSlice.reducer,
  auroraQuickcliQsReport: auroraQuickcliQsReportSlice.reducer,
  personalInfo: personalInfoSlice.reducer,
  slackBot: slackBotSlice.reducer,
  advancedFilters: advancedFiltersSlice.reducer,
  programAdmins: programAdminsSlice.reducer,
  programSponsors: programSponsorsSlice.reducer,
  possibleValuesHRISField: possibleValuesHRISFieldSlice.reducer,
  peopleCheckUpdateAccess: peopleCheckUpdateAccessSlice.reducer,
  peopleTrackingReports: peopleTrackingReportsSlice.reducer,
  programLogs: programLogsSlice.reducer,
  generatedReportInfo: generatedReportInfoSlice.reducer,
  aiChat: cliqAiSlice.reducer,
  reportBuilderReportsList: reportBuilderReportsListSlice.reducer,
  reportBuilderConfig: reportBuilderConfigSlice.reducer,
  reportBuilderModulesAndFields: reportBuilderModulesAndFieldsSlice.reducer,
  reportBuilderData: reportBuilderDataSlice.reducer,
});
