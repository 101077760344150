import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface AppLinkProps extends NavLinkProps {
  underline?: boolean;
  children?: ReactNode;
}

const AppLink: FC<AppLinkProps> = ({ underline = true, ...props }) => (
  <NavLink className={classNames('app-link', { underline })} {...props} />
);

export default AppLink;
