import { defineMessages } from 'react-intl';

export const APP_GLOBAL_MESSAGES = defineMessages({
  comingSoon: {
    id: 'global.coming.soon',
    defaultMessage: 'Data coming soon',
    description: '[Global] coming soon',
  },
  profileTypeListSelectInfo: {
    id: 'profile.type.select.info',
    defaultMessage:
      'The most common usage is for this to be a drop-down list of values that are provided by the customer.',
    description: '[ProfileType] modal Info text',
  },
  invited: {
    id: 'invited',
    defaultMessage: 'Invited',
    description: '[Global] invited label',
  },
  transcriptLabel: {
    defaultMessage: 'transcript',
    description: '[Global] transcript',
    id: 'global.resource.item.transcript',
  },
  transcriptFileName: {
    defaultMessage: 'transcript{ext}',
    description: '[Global] transcript file name',
    id: 'global.resource.item.transcript.name',
  },
  confirm: {
    id: 'global.confirm',
    defaultMessage: 'Confirm',
    description: '[Global] confirm label',
  },
  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel',
    description: '[Global] cancel label',
  },
  undoAllChanges: {
    id: 'global.undo.all.changes',
    defaultMessage: 'Undo All Changes',
    description: '[Global] Undo All Changes label',
  },
  show: {
    id: 'global.show',
    defaultMessage: 'Show',
    description: '[Global] show label',
  },
  hide: {
    id: 'global.hide',
    defaultMessage: 'Hide',
    description: '[Global] Hide label',
  },
  videoYoutubeLabel: {
    defaultMessage: 'Youtube',
    description: '[Global] video badge label',
    id: 'global.video.youtube.label',
  },
  linkResourceButtonLabel: {
    defaultMessage: 'Visit Link',
    description: '[ResourceLibrary] Link button text',
    id: 'resource.library.link.button.text',
  },
  linkResourceCellLabel: {
    defaultMessage: 'Link',
    description: '[ResourceLibrary] Link Cell title',
    id: 'resource.library.link.cell.title',
  },
  linkResourceAriaLabel: {
    defaultMessage: 'Open Link: {itemTitle}',
    description: '[ResourceLibrary] Resource Library Item Aria Label',
    id: 'resource.library.item.open.link.aria.label',
  },
  downloadResourceButtonLabel: {
    defaultMessage: 'Download',
    description: '[ResourceLibrary] Document button text',
    id: 'resource.library.download.resource.button.text',
  },
  documentResourceCellLabel: {
    defaultMessage: 'Document',
    description: '[ResourceLibrary] Document Cell title',
    id: 'resource.library.document.cell.title',
  },
  downloadResourceAriaLabel: {
    defaultMessage: 'Download File: {itemTitle}',
    description: '[ResourceLibrary] Resource Library Item Aria Label',
    id: 'resource.library.item.download.aria.label',
  },
  imageResourceCellLabel: {
    defaultMessage: 'Image',
    description: '[ResourceLibrary] Image Cell title',
    id: 'resource.library.image.cell.title',
  },
  videoResourceButtonLabel: {
    defaultMessage: 'Watch',
    description: '[ResourceLibrary] Video button text',
    id: 'resource.library.video.button.text',
  },
  videoResourceCellLabel: {
    defaultMessage: 'Video',
    description: '[ResourceLibrary] Video Cell title',
    id: 'resource.library.video.cell.title',
  },
  videoResourceAriaLabel: {
    defaultMessage: 'Play Video: {itemTitle}',
    description: '[ResourceLibrary] Resource Library Item Aria Label',
    id: 'resource.library.item.play.video.aria.label',
  },
  accessibleVersionLabel: {
    defaultMessage: 'Accessible File Version',
    description: '[ResourceLibrary] Tooltip',
    id: 'resource.library.accessible.version.label',
  },
  editResourceLabel: {
    defaultMessage: 'Edit Resource',
    description: '[ManageResources] Edit Tooltip text',
    id: 'resource.library.item.edit.tooltip.text',
  },
  restoreResourceLabel: {
    defaultMessage: 'Restore Resource',
    description: '[ManageResources] Restore Tooltip text',
    id: 'resource.library.item.restore.tooltip.text',
  },
  hideResourceLabel: {
    defaultMessage: 'Hide Resource',
    description: '[ManageResources] Hide Tooltip text',
    id: 'resource.library.item.hide.tooltip.text',
  },
  deleteResourceLabel: {
    defaultMessage: 'Delete Resource',
    description: '[ManageResources] Delete Tooltip text',
    id: 'resource.library.item.delete.tooltip.text',
  },
  delete: {
    id: 'global.delete',
    defaultMessage: 'Delete',
    description: '[Global] delete label',
  },
  contactSupport: {
    id: 'global.contact.support',
    defaultMessage: 'Contact Support',
    description: '[Global] contact support label',
  },
  learnMore: {
    id: 'global.learn.more',
    defaultMessage: 'Learn More',
    description: '[Global] learn more label',
  },
  close: {
    id: 'global.close',
    defaultMessage: 'Close',
    description: '[Global] close label',
  },
  save: {
    id: 'global.save',
    defaultMessage: 'Save',
    description: '[Global] save label',
  },
  saveChanges: {
    id: 'global.save.changes',
    defaultMessage: 'Save Changes',
    description: '[Global] Save Changes  label',
  },
  publish: {
    id: 'global.publish',
    defaultMessage: 'Publish',
    description: '[Global] save label',
  },
  saveAndPublish: {
    id: 'global.save.and.publish',
    defaultMessage: 'Save And Publish',
    description: '[Global] save label',
  },
  programFieldEditSuccess: {
    id: 'messages.success.program.field.edit',
    defaultMessage:
      'Your {field} update has been saved as a program draft. Publish your update(s) by clicking the “Publish Draft” button.',
    description: '[EditProgram] submit action masseage',
  },
  programRetiredSuccess: {
    id: 'messages.success.program.retired',
    defaultMessage: 'Your Program has been retired.',
    description: '[ProgramWizard] Alert message',
  },
  listDescriptor: {
    id: 'public.list.descriptor',
    defaultMessage: '{label}: {list}',
    description: '[Global] List Descriptor',
  },
  somethingWrong: {
    id: 'public.messages.something.wrong',
    defaultMessage: 'Something goes wrong. Please try again',
    description: '[ProgramWizard] Alert message',
  },
  requestProcessingError: {
    id: 'error.request.processing',
    defaultMessage:
      'The request couldn’t be processed at this time. Please try again or reach out to support@mentorcliq.com for further assistance.',
    description: '[ProgramWizard] Alert message',
  },
  profileNotAvailable: {
    id: 'profile.not.available',
    defaultMessage: 'No Profile Information Available',
    description: '[Invitations] Notification text',
  },
  newLabelMarker: {
    id: 'global.new.label.marker',
    defaultMessage: '(New Label)',
    description: '[Global] New Label Marker',
  },
  messageTemplateSaved: {
    id: 'messages.message.template.save.success',
    defaultMessage: 'Your message template has been saved.',
    description: '[Invitations] Notification text',
  },
  roleEnrollmentInfoUpdated: {
    id: 'enrollment.role.info.updated.success',
    defaultMessage: 'Your {roleName} Enrollment Information for {programName} have been updated!',
    description: '[SendCommunication] Alert message',
  },
  relationshipLeavedSuccess: {
    id: 'relationship.leave.success',
    defaultMessage: "You've successfully left your relationship with {participantName}",
    description: '[Global] Alert message',
  },
  brandingUpdateSuccess: {
    id: 'branding.update.success',
    defaultMessage: `Your branding has been updated successfully.`,
    description: '[Branding] success message',
  },
  brandingResetSuccess: {
    id: 'branding.reset.success',
    defaultMessage: `Your branding has been reset.`,
    description: '[Branding] success message',
  },
  calendarSaveSuccess: {
    id: 'calendar.save.success',
    defaultMessage: 'Calendar settings saved successfully.',
    description: '[CalendarConfiguration] Notification text',
  },
  gdprSavedSuccessfully: {
    id: 'gdpr.saved.successfully',
    defaultMessage: 'Data Privacy & GDPR saved successfully.',
    description: '[Gdpr Privacy] Notification text',
  },
  platformConfigSavedSuccessfully: {
    id: 'platform.config.saved.successfully',
    defaultMessage: 'Platform configuration saved successfully.',
    description: '[PlatformConfiguration] Alert message',
  },
  platformInfoUpdateSuccess: {
    id: 'platform.info.update.success',
    defaultMessage: 'Platform Information updated.',
    description: '[PlatformInfo] alert message',
  },
  languagesUpdateSuccess: {
    id: 'languages.update.success',
    defaultMessage: 'Languages saved successfully.',
    description: '[Languages] alert message',
  },
  reviewFollowingIssues: {
    id: 'review.following.issues',
    defaultMessage: 'Please review the following fields or issues:',
    description: '[PlatformManageResources] Alert title',
  },
  changesPublishSuccess: {
    id: 'changes.publish.success',
    defaultMessage: 'Your changes were successfully published!',
    description: '[Invitations] Notification text',
  },
  profileDisplaySaveSuccess: {
    id: 'profile.display.save.success',
    defaultMessage: 'Profile display saved successfully.',
    description: '[DisplayField] Notification text',
  },
  profileFieldsSavedSuccessfully: {
    id: 'profile.fields.saved.successfully',
    defaultMessage: 'Profile fields saved successfully.',
    description: '[ProfileFields] Alert message',
  },
  resourceSuggestionSentSuccess: {
    id: 'resource.suggestion.sent.success',
    defaultMessage:
      'Your resource suggestion has been sent. Our staff will reach out via email if they have any additional questions.',
    description: '[SuggestResource] alert text',
  },
  enrollmentApproved: {
    id: 'enrollment.approved',
    defaultMessage: 'Enrollment was approved!',
    description: '[Enrollment] alert message',
  },
  enrollmentDeclined: {
    id: 'enrollment.declined',
    defaultMessage: 'Enrollment was declined!',
    description: '[Enrollment] alert message',
  },
  configureCriteriaValidation: {
    defaultMessage: 'Please configure custom criteria',
    description: '[Validation] message',
    id: 'match.rule.criteria.config.validation.message',
  },
  selectFieldFromList: {
    defaultMessage: 'Please select a field from list',
    description: '[Validation] message',
    id: 'select.field.from.list',
  },
  selectValuePlaceholder: {
    defaultMessage: '- Select a value -',
    id: 'public.select.value.placeholder',
    description: '[Global] Select Value Placeholder',
  },
  selectFieldPlaceholder: {
    defaultMessage: '- Select an existing field -',
    id: 'public.select.field.placeholder',
    description: '[Global] Select Value Placeholder',
  },
  selectValuesPlaceholder: {
    defaultMessage: '- Select values -',
    id: 'public.select.values.placeholder',
    description: '[Global] Select Values Placeholder',
  },
  resourcesSectionTitleMaxLength: {
    defaultMessage: 'Section title should be maximum {length} characters.',
    description: '[Validation] Resources section title length validation message',
    id: 'resources.section.title.length.validation.message',
  },
  statusNone: {
    id: 'status.none',
    defaultMessage: 'None',
    description: '[Global] status label',
  },
  statusActive: {
    id: 'status.active',
    defaultMessage: 'Active',
    description: '[Global] status label',
  },
  statusPending: {
    id: 'status.pending',
    defaultMessage: 'Pending',
    description: '[Global] status label',
  },
  statusFinished: {
    id: 'status.finished',
    defaultMessage: 'Finished',
    description: '[Global] status label',
  },
  statusCompleted: {
    id: 'status.completed',
    defaultMessage: 'Completed',
    description: '[Global] status label',
  },
  statusExpired: {
    id: 'status.expired',
    defaultMessage: 'Expired',
    description: '[Global] status label',
  },
  statusDeclined: {
    id: 'status.declined',
    defaultMessage: 'Declined',
    description: '[Global] status label',
  },
  statusNeverMatched: {
    id: 'status.never.matched',
    defaultMessage: 'Never Matched',
    description: '[Global] status label',
  },
  ignoreMatchingRules: {
    id: 'matching.rules.ignore',
    defaultMessage: 'Ignore Matching Rules',
    description: '[MatchingRules] term label',
  },
  statusAvailable: {
    id: 'status.available',
    defaultMessage: 'Available',
    description: '[Availability] term label',
  },
  statusUnAvailable: {
    id: 'status.unavailable',
    defaultMessage: 'Unavailable',
    description: '[Availability] term label',
  },
  statusAny: {
    id: 'status.any',
    defaultMessage: 'Any',
    description: '[Availability] term label',
  },
  statusMatched: {
    id: 'status.matched',
    defaultMessage: 'Matched',
    description: '[MatchStatus] term label',
  },
  statusUnMatched: {
    id: 'status.unmatched',
    defaultMessage: 'Unmatched',
    description: '[MatchStatus] term label',
  },
  noSuggestions: {
    id: 'match.no.suggestions',
    defaultMessage: 'No Suggestions',
    description: '[MatchSuggestion] term label',
  },
  suggestedMatch: {
    id: 'match.suggest',
    defaultMessage: 'Suggested a Match',
    description: '[MatchSuggestion] term label',
  },
  userDashBoardMyCliQTabLabel: {
    id: 'user.dashboard.tab.my-cliq.label',
    defaultMessage: 'My CliQ',
    description: '[Dashboard.Tabs] Tab label',
  },
  userDashBoardProgramsTabLabel: {
    id: 'user.dashboard.tab.programs.label',
    defaultMessage: 'Programs',
    description: '[Dashboard.Tabs] Tab label',
  },
  userDashBoardTimelineTabLabel: {
    id: 'user.dashboard.tab.timeline.label',
    defaultMessage: 'Timeline',
    description: '[Dashboard.Tabs] Tab label',
  },
  userDashBoardReportsTabLabel: {
    id: 'user.dashboard.tab.reports.label',
    defaultMessage: 'Reports',
    description: '[Dashboard.Tabs] Tab label',
  },
  userDashBoardProfileTabLabel: {
    id: 'user.dashboard.tab.profile.label',
    defaultMessage: 'Profile',
    description: '[Dashboard.Tabs] Tab label',
  },
  globalDateRangeLast1Months: {
    description: 'dateRange.last1Months',
    defaultMessage: 'Last 1 month',
    id: 'global.dateRange.last1Months',
  },
  globalStatCardExploreOpenPrograms: {
    id: 'global.stat.card.explore.open.programs',
    description: '[StatsItem] Button text',
    defaultMessage: 'Explore Open Programs',
  },
  globalStatCardDevelopmentTimeline: {
    id: 'global.stat.card.development.timeline',
    description: '[StatsItem] Button text',
    defaultMessage: 'Development Timeline',
  },
  globalStatCardDevelopmentReport: {
    id: 'global.stat.card.development.report',
    description: '[StatsItem] Button text',
    defaultMessage: 'Development Report',
  },
  approvalRequestPreferencesTitle: {
    defaultMessage: 'Program Enrollment Preferences for {participant}',
    description: '[Approval Declined] preferences title',
    id: 'public.approval.request.preferences.title',
  },
  approvalDeclineModalBody: {
    defaultMessage:
      'Feel free to include an optional note about why you are declining the request. This will be included in a notification to the participants.',
    description: '[Approval Decline] modal body',
    id: 'public.approval.decline.modal.body',
  },
  exportTranslations: {
    id: 'export.translations.label',
    defaultMessage: 'Export Translations',
    description: '[Configure Languages] export label',
  },
  importTranslations: {
    id: 'import.translations.label',
    defaultMessage: 'Import Translations',
    description: '[Configure Languages] import label',
  },
  languagesDefaultPhrase: {
    defaultMessage: 'Default Phrase',
    description: '[Configure Languages] translatable phrase',
    id: 'default.language.phrase.label',
  },
  languagesDefaultPhraseDescription: {
    defaultMessage: 'Phrase Description',
    description: '[Configure Languages] translatable phrase',
    id: 'default.language.phrase.description.label',
  },
  languagesSelectedLanguageLabel: {
    defaultMessage: 'Selected Language ({code})',
    description: '[Configure Languages] selected language',
    id: 'selected.language.label',
  },
  languagesTranslatedLanguageLabel: {
    defaultMessage: 'Translation',
    description: '[Configure Languages] phrase translation',
    id: 'selected.language.translation.label',
  },
  languagesAutoTranslateLabel: {
    defaultMessage: 'Translate',
    description: '[Configure Languages] suggest translation',
    id: 'selected.language.auto.translate.label',
  },
  globalBackToHome: {
    defaultMessage: 'Back to Home',
    description: '[Global] Back to home label',
    id: 'public.back.to.home',
  },
  globalBackToMyCliQ: {
    defaultMessage: 'Back To My CliQ',
    description: '[Global] Back to my CliQ label',
    id: 'public.back.to.my.cliq',
  },
  viewAsRestrictedActionDescription: {
    defaultMessage: 'Only participants can take this action.',
    description: '[ViewAs] not allowed',
    id: 'public.viewer.restricted.action.description',
  },
  onlyParticipantCanTakeThisAction: {
    defaultMessage: 'Only the participant can take this action.',
    description: '[Global] Only the participant can take this action text',
    id: 'global.only.participant.can.take.action',
  },
  surveyBuilderSaveSuccess: {
    id: 'survey.builder.save.success',
    defaultMessage: 'Survey Builder saved successfully',
    description: '[ProfileFields] Alert message',
  },
  selectSuggestedMatch: {
    defaultMessage: '{index} suggestion.',
    description: '[Suggestions] info text',
    id: 'suggestions.select',
  },
  selectSuggestedMatchWithHash: {
    defaultMessage: '{index} Suggestion',
    description: '[Global] Suggestions with hash',
    id: 'global.suggestions.with.hash',
  },
  globalMemberWithRoleName: {
    defaultMessage: 'with {member} <strong>({roleName})</strong>',
    description: '[RecentActivity] formatted list item',
    id: 'public.member.with.role',
  },
  globalDateTodayName: {
    defaultMessage: 'Today',
    description: '[Date] today',
    id: 'public.date.today',
  },
  globalDateYesterdayName: {
    defaultMessage: 'Yesterday',
    description: '[Date] yesterday',
    id: 'public.date.yesterday',
  },
  globalSearch: {
    id: 'global.placeholder.search',
    description: '[Global] Placeholder Search',
    defaultMessage: 'Search...',
  },
  globalPlaceholderSearchTerms: {
    id: 'global.placeholder.search.terms',
    description: '[Global] Placeholder Search Terms',
    defaultMessage: 'Search term(s)...',
  },
  globalSearchByNameLabel: {
    defaultMessage: 'Search by name or e-mail',
    description: '[Global] Search by name or email placeholder',
    id: 'global.search.by.name',
  },
  globalSearchByNameTagLabel: {
    defaultMessage: 'Name or E-mail: {label}',
    description: '[Search] Name or E-mail tag',
    id: 'global.search.name.or.email.tag.label',
  },
  globalSearchByTermTagLabel: {
    defaultMessage: 'Term(s): {termsSearch}',
    description: '[Search] Term(s) tag',
    id: 'global.search.term.tag.label',
  },
  globalRoleTagLabel: {
    defaultMessage: 'Role: {label}',
    description: '[Global] Role tag label',
    id: 'global.role.tag.label',
  },
  globalSearchByDateRangeLabel: {
    defaultMessage: 'Date Range',
    description: '[Global] Search by date range placeholder',
    id: 'global.search.date.range.label',
  },
  globalProgramLabel: {
    defaultMessage: 'Program',
    description: '[Global] Program label',
    id: 'global.program.label',
  },
  globalRoleLabel: {
    defaultMessage: 'Role',
    description: '[Global] Role label',
    id: 'global.role.label',
  },
  globalResourceTypeLabel: {
    defaultMessage: 'Resource Type',
    description: '[Global] Resource type label',
    id: 'global.resource.type.label',
  },
  globalSectionLabel: {
    defaultMessage: 'Section',
    description: '[Global] Section label',
    id: 'global.section.label',
  },
  globalEnrolledLabel: {
    defaultMessage: 'Enrolled',
    description: '[Global] Enrolled label',
    id: 'global.enrolled.label',
  },
  globalMatchedLabel: {
    defaultMessage: 'Matched',
    description: '[Global] Matched label',
    id: 'global.matched.label',
  },
  globalDownloadLabel: {
    id: 'global.download.label',
    description: '[Global] Download Label',
    defaultMessage: 'Download',
  },
  chatConcludedRelationships: {
    defaultMessage: 'Concluded Relationships',
    description: '[Chat] concluded relationships label',
    id: 'chat.concluded.relationships.label',
  },
  globalMilestonesButtonLabel: {
    description: '[Global] Milestones icon text',
    defaultMessage: 'Milestones',
    id: 'global.milestones.button.label',
  },
  globalQuickcliQsLabel: {
    description: '[Global] QuickcliQs label',
    defaultMessage: 'QuickcliQs',
    id: 'global.quickcliqs.label',
  },
  globalTeamsButtonLabel: {
    description: '[Global] Teams icon text',
    defaultMessage: 'Teams',
    id: 'global.teams.button.label',
  },
  globalSkypeButtonLabel: {
    description: '[Global] Teams icon text',
    defaultMessage: 'Skype',
    id: 'global.skype.button.label',
  },
  globalSlackButtonLabel: {
    description: '[Global] Slack icon text',
    defaultMessage: 'Slack',
    id: 'global.slack.button.label',
  },
  globalWebexButtonLabel: {
    description: '[Global] Webex icon text',
    defaultMessage: 'Webex',
    id: 'global.webex.button.label',
  },
  globalGoalsButtonLabel: {
    description: '[Global] Goals icon text',
    defaultMessage: 'Goals',
    id: 'global.goals.button.label',
  },
  globalEmailButtonLabel: {
    description: '[Global] Email icon text',
    defaultMessage: 'Email',
    id: 'global.email.button.label',
  },
  globalEmailLabel: {
    description: '[Global] E-mail icon text',
    defaultMessage: 'Email',
    id: 'public.global.email.label',
  },
  globalPasswordLabelText: {
    description: '[Global] Password label text',
    defaultMessage: 'Password',
    id: 'public.global.password.label.text',
  },
  globalDashedEmailLabel: {
    description: '[Global] Email label with dash',
    defaultMessage: 'E-mail',
    id: 'global.dashed.email.label',
  },
  globalChatButtonLabel: {
    description: '[Global] Chat icon text',
    defaultMessage: 'Chat',
    id: 'global.chat.button.label',
  },
  globalMeetButtonLabel: {
    description: '[Global] Meet icon text',
    defaultMessage: 'Meet',
    id: 'global.meet.button.label',
  },
  matchedSince: {
    description: '[Global] Matched since info',
    defaultMessage: '<strong>Matched since:</strong> {date} <em>({months} months)</em>',
    id: 'global.matched.since.label',
  },
  globalPowerpoint: {
    defaultMessage: 'Powerpoint',
    description: '[Powerpoint] label',
    id: 'global.powerpoint',
  },
  globalVideo: {
    defaultMessage: 'Video',
    description: '[Video] label',
    id: 'global.video',
  },
  globalWord: {
    defaultMessage: 'Word',
    description: '[Word] label',
    id: 'global.word',
  },
  globalPhotoVideo: {
    defaultMessage: 'Other',
    description: '[Photo-Video] label',
    id: 'global.photo.video',
  },
  globalCopyLabel: {
    defaultMessage: 'Copy',
    description: '[Tooltip] Copy Label',
    id: 'global.copy.label',
  },
  globalTooltipCopiedText: {
    defaultMessage: 'Copied',
    description: '[Tooltip] copied text',
    id: 'global.tooltip.copied.text',
  },
  globalTooltipCopyHelpText: {
    defaultMessage: 'Copy to Clipboard',
    description: '[Tooltip] help text',
    id: 'global.tooltip.copy.help.text',
  },
  globalTooltipLinkCopyHelpText: {
    defaultMessage: 'Copy Sharable Link',
    description: '[Tooltip] Link Copy Help Text',
    id: 'global.tooltip.link.copy.help.text',
  },
  globalTooltipCopiedErrorText: {
    defaultMessage: 'Failed to copy',
    description: '[Tooltip] error text',
    id: 'global.tooltip.copy.failed.error.text',
  },
  wrongResourceUrl: {
    defaultMessage: 'Please type or paste a valid URL, starting with https:// or http:// or correct uploaded filename.',
    description: '[ResourceLibrary] Url validation message',
    id: 'resource.validation.invalid.url',
  },
  invalidFileSize: {
    defaultMessage: 'Uploaded file size exceeded {size} MB.',
    description: '[Global] invalid file size',
    id: 'global.invalid.file.size',
  },
  invalidFileFormat: {
    defaultMessage: "Uploaded File Type doesn't match with {extensions}",
    description: '[Global] invalid file format',
    id: 'global.invalid.file.format',
  },
  suggestMatch: {
    defaultMessage: 'Suggest a Match',
    description: '[Global] Suggest match',
    id: 'suggest.match.button',
  },
  requestMatch: {
    defaultMessage: 'Request a Match',
    description: '[Global] Request match',
    id: 'global.request.match',
  },
  suggestMatchButtonTooltip: {
    defaultMessage:
      'You cannot suggest more than three matches. Please remove a suggestion before you can add another one.',
    description: '[Global] Suggest match',
    id: 'suggest.match.button.tooltip',
  },
  removeSuggestionButtonLabel: {
    defaultMessage: 'Remove Suggestion',
    description: '[Global] Suggest match',
    id: 'remove.suggestion.button',
  },
  valuesAlreadyExists: {
    defaultMessage: '{items} already exists.',
    description: '[Validation] duplicate possible values validation message',
    id: 'public.duplicate.possible.values.validation.message',
  },
  fieldIsRequired: {
    id: 'public.validation.field.required',
    defaultMessage: `Field is required`,
    description: '[Validation] validation message',
  },
  fieldLabelIsRequiredAndUnique: {
    defaultMessage: 'Field label is required to save, and it must be unique.',
    description: '[Validation] validation message',
    id: 'question.config.form.field.label.is.required.and.unique',
  },
  emailAlreadyExists: {
    id: 'public.validation.email.exists',
    defaultMessage: `User with this Email already exists`,
    description: '[Validation] New User Email validation message',
  },
  maxDefaultValuesError: {
    defaultMessage: `Max Default Value is {max}`,
    description: '[QuestionConfigForm] Validation message',
    id: 'question.config.form.max.default.value',
  },
  minValueRangeError: {
    defaultMessage: 'Min value is {min}',
    description: '[QuestionConfigForm] Validation message',
    id: 'question.config.form.min.value',
  },
  maxValueRangeError: {
    defaultMessage: 'Max Value must be greater than or equal to {max}',
    description: '[QuestionConfigForm] Validation message',
    id: 'question.config.form.max.value',
  },
  tokenIsRequired: {
    defaultMessage: `{token} token is required.`,
    description: '[Validation] validation message',
    id: 'public.token.is.required',
  },
  textCharsValidationError: {
    id: 'public.validation.character.validation',
    defaultMessage: 'Text validation failure',
    description: '[Validation] message',
  },
  questionPossibleValuesShouldBeUnique: {
    defaultMessage: 'Question possible values should be unique',
    description: '[Validation] message',
    id: 'question.config.form.possible.unique.value',
  },
  subFieldPossibleValuesShouldBeUnique: {
    defaultMessage: 'Sub-field possible values should be unique',
    description: '[Validation] message',
    id: 'question.config.form.group.possible.unique.value',
  },
  questionPossibleValuesAreRequired: {
    defaultMessage: 'Question possible values are required',
    description: '[Validation] message',
    id: 'question.config.form.possible.required.value',
  },
  minOneRowIsRequired: {
    defaultMessage: 'Minimum number of rows should be 1.',
    description: '[Validation] validation message',
    id: 'question.config.form.min.number.of.rows',
  },
  minOneItemShouldBeAdded: {
    defaultMessage: 'At least one item should be added',
    description: '[Validation] message',
    id: 'question.config.form.possible.minimum.count',
  },
  defaultValueIsUnchecked: {
    defaultMessage: 'Default value is unchecked in the available options!',
    description: '[Validation] message',
    id: 'question.config.form.default.value.is.unchecked',
  },
  messageFieldIsRequired: {
    defaultMessage: 'Message field is required.',
    description: '[Validation] message',
    id: 'public.message.field.is.required',
  },
  subjectFieldIsRequired: {
    defaultMessage: 'Subject field is required.',
    description: '[Validation] message',
    id: 'public.subject.field.is.required',
  },
  subjectMaxLengthError: {
    defaultMessage: 'Subject cannot be longer than 255 characters.',
    description: '[Validation] message',
    id: 'public.subject.cannot.be.longer.than.255',
  },
  fieldIsTooShort: {
    id: 'public.validation.field.is.short',
    defaultMessage: 'Field Length too short!',
    description: '[Validation] message',
  },
  fieldIsTooLong: {
    id: 'public.validation.field.is.long',
    defaultMessage: 'Field Length too long!',
    description: '[Validation] message',
  },
  fieldIsNotUnique: {
    id: 'public.validation.field.unique',
    defaultMessage: `Field should be unique`,
    description: '[Validation] validation message',
  },
  fieldIsPercentage: {
    defaultMessage: 'Please input a number in range 0,100',
    description: '[Validation] message',
    id: 'public.validation.field.is.percentage',
  },
  fieldIsMin0: {
    defaultMessage: 'Field value should be more than 0!',
    description: '[Validation] message',
    id: 'public.validation.field.is.min.0',
  },
  programNameIsRequired: {
    id: 'public.validation.program.name.required',
    defaultMessage: `Required! Program name can't be empty`,
    description: '[Validation] validation message',
  },
  programNameShouldBeUnique: {
    id: 'public.validation.program.name.unique',
    defaultMessage: `Program name should be unique`,
    description: '[Validation] validation message',
  },
  minValue: {
    id: 'public.validation.min.value',
    defaultMessage: `Min value 0`,
    description: '[Validation] validation message',
  },
  urlFormat: {
    id: 'public.validation.url.field.format',
    defaultMessage: `Field should have valid URL format`,
    description: '[Validation] validation message',
  },
  focusAreaFieldLength: {
    id: 'public.validation.focus.area.field.length.validation',
    defaultMessage: 'Your input in the Other topics not covered above field must be less than 5,000 characters.',
    description: '[Validation] message',
  },
  emailFieldFormat: {
    id: 'public.validation.email.field.format.validation',
    defaultMessage: `Please enter a valid E-mail address`,
    description: '[Validation] E-mail validation message',
  },
  textFormat: {
    id: 'public.validation.text.field.format.validation',
    defaultMessage: `Field should have valid format`,
    description: '[Validation] Text Field regexp validation message',
  },
  phoneFormat: {
    id: 'public.validation.text.field.phone.format.validation',
    defaultMessage: `Field should have valid phone number format`,
    description: '[Validation] Phone type Text Field validation message',
  },
  passwordFormatRequired: {
    id: 'public.validation.passwords.format',
    defaultMessage: 'Password must be at least 8 characters',
    description: '[Validation] message',
  },
  stringMaxCharactersValidation: {
    id: 'public.validation.string.max.chars',
    defaultMessage: 'The field should not exceed {count} characters',
    description: '[Validation] message',
  },
  optionsValidation: {
    id: 'public.validation.notification.channels.error.message',
    defaultMessage: 'At least one option should be selected.',
    description: '[Account Edit] validation message',
  },
  accountAlreadyExists: {
    id: 'public.validation.email.registered',
    defaultMessage: 'Account already exists',
    description: '[Validation] message',
  },
  emailFieldIsRequired: {
    id: 'public.validation.email.required',
    defaultMessage: 'E-mail field is required',
    description: '[Validation] message',
  },
  passwordMustMatch: {
    id: 'public.validation.passwords.must.match',
    defaultMessage: 'Passwords must match.',
    description: '[Validation] message',
  },
  confirmPasswordFieldIsRequired: {
    id: 'public.validation.confirm.password.required',
    defaultMessage: 'Confirm password field is required.',
    description: '[Validation] message',
  },
  passwordFieldIsRequired: {
    id: 'public.validation.password.required',
    defaultMessage: 'Password field is required',
    description: '[Validation] message',
  },
  currentPasswordIsRequired: {
    id: 'public.validation.current.password.required',
    defaultMessage: 'Current Password field is required.',
    description: '[Validation] message',
  },
  listFieldsValidator: {
    id: 'public.validation.fields.validator',
    defaultMessage: 'This field can not hold more than {length} values.',
    description: '[Validation] validation message',
  },
  yesNoNoPrefFieldConfigIncludeNoPref: {
    defaultMessage: 'Include "No Preference" Option',
    description: '[QuestionConfig] Yes/No/NoPref field include no preference',
    id: 'question.config.yesnonopref.include.nopref',
  },
  yesNoNoPrefFieldPerRoleOptionsConfigDescription: {
    defaultMessage: "You may define which of the question's available options will be displayed to {roleName}.",
    description: '[QuestionConfig] Yes/No/NoPref field per role config available options description',
    id: 'question.config.yesnonopref.options.description',
  },
  yesNoNoPrefFieldPerRoleOptionsConfigLabel: {
    defaultMessage: 'Available Options',
    description: '[QuestionConfig] Yes/No/NoPref field per role config available options label',
    id: 'question.config.yesnonopref.options.label',
  },
  listTextFieldPerRoleOptionsConfigDescription: {
    defaultMessage: "You may define which of the question's available options will be displayed to {roleName}.",
    description: '[QuestionConfig] List Text field per role config available options description',
    id: 'question.config.list.text.options.description',
  },
  listTextFieldPerRoleOptionsConfigLabel: {
    defaultMessage: 'Available Options',
    description: '[QuestionConfig] List Text field per role config available options label',
    id: 'question.config.list.text.options.label',
  },
  questionConfigDefaultOptionLabel: {
    description: '[QuestionConfig] Default option label',
    defaultMessage: 'Default',
    id: 'question.config.default.option.label',
  },
  questionConfigDefaultValueLabel: {
    description: '[QuestionConfig] Default value label',
    defaultMessage: 'Default Value',
    id: 'question.config.default.value.label',
  },
  minNumberValue: {
    id: 'public.validation.number.min.value',
    defaultMessage: `Min value {value}`,
    description: '[Validation] validation message',
  },
  maxNumberValue: {
    id: 'public.validation.number.max.value',
    defaultMessage: `Max value {value}`,
    description: '[Validation] validation message',
  },
  roiBoostedCalculatorHeader: {
    defaultMessage: 'Your Employee Retention Improvement',
    description: '[BoostedCalculator] Header',
    id: 'roi.calculator.boosted.header',
  },
  roiFreeCalculatorHeader: {
    defaultMessage: 'Your Estimated Employee Retention Improvement',
    description: '[BoostedCalculator] Header',
    id: 'roi.calculator.free.header',
  },
  platformSettingsTitle: {
    id: 'platform.settings.title',
    defaultMessage: 'Platform Settings',
    description: '[Platform] Settings title',
  },
  platformSettingsLabel: {
    id: 'platform.settings.breadcrumb.label',
    defaultMessage: 'Platform Settings - {page}',
    description: '[Platform] Settings title',
  },
  platformBrandingLabel: {
    id: 'platform.branding.label',
    defaultMessage: 'Branding',
    description: '[Platform] Branding title',
  },
  platformCalendarLabel: {
    id: 'platform.calendar.label',
    defaultMessage: 'Calendar',
    description: '[Platform] Calendar title',
  },
  platformCollaborationToolsLabel: {
    id: 'platform.collaboration.tools.label',
    defaultMessage: 'Collaboration Tools',
    description: '[Platform] Collaboration Tools title',
  },
  platformGdprPrivacyLabel: {
    id: 'platform.gdpr.privacy.label',
    defaultMessage: 'Data Privacy & GDPR',
    description: '[Platform] Data Privacy & GDPR Tools title',
  },
  platformBaseTranslationsLabel: {
    id: 'platform.base.translations.label',
    defaultMessage: 'Languages (Base Translations)',
    description: '[Platform] Base Translations title',
  },
  platformLanguagesFiltersLabel: {
    defaultMessage: 'Language Filters:',
    description: '[Languages.Filters] filters label',
    id: 'configure.languages.filters.label',
  },
  platformLanguagesFiltersKeywordTagLabel: {
    defaultMessage: 'Message: {keyword}',
    description: '[Languages.Filters] keyword tag',
    id: 'languages.filters.keyword.value',
  },
  platformLanguagesFiltersPlatformMessagesOptionLabel: {
    defaultMessage: 'Platform messages',
    description: '[CustomLanguages.Filters] Message source item',
    id: 'languages.filters.platform.messages.option',
  },
  platformLanguagesLabel: {
    id: 'platform.languages.label',
    defaultMessage: 'Languages',
    description: '[Platform] Languages title',
  },
  platformConfigurationsLabel: {
    id: 'platform.configurations.label',
    defaultMessage: 'Configurations',
    description: '[Platform] Configurations title',
  },
  platformInfoConfigLabel: {
    id: 'platform.info.config.label',
    defaultMessage: 'Platform Info',
    description: '[Platform] Platform Info title',
  },
  platformManageResourcesLabel: {
    id: 'platform.manage.resources.label',
    defaultMessage: 'Manage Resources',
    description: '[Platform] Manage Resources label',
  },
  platformManageResourcesTitle: {
    id: 'platform.manage.resources.title',
    defaultMessage: 'Manage Platform Resources',
    description: '[Platform] Manage Platform Resources title',
  },
  platformProfileDisplayLabel: {
    id: 'platform.profile.display.title',
    defaultMessage: 'Profile Display',
    description: '[Platform] Profile Display label',
  },
  platformProfileFieldsLabel: {
    id: 'platform.profile.fields.title',
    defaultMessage: 'Profile Fields',
    description: '[Platform] Profile Fields label',
  },
  platformSSOConfigurationLabel: {
    id: 'platform.sso.configuration.title',
    defaultMessage: 'SSO Configuration',
    description: '[Platform] SSO Configuration label',
  },
  platformZoomConfigurationLabel: {
    id: 'platform.zoom.configuration.title',
    defaultMessage: 'Zoom',
    description: '[Platform] Zoom Configuration label',
  },
  entries: {
    defaultMessage: 'Entries',
    description: '[Global] Entries Label',
    id: 'public.entries.label',
  },
  scheduledCommunicationSendDateLabel: {
    defaultMessage:
      '{interval} {period} after {trigger} date {reminderInterval, plural, =0 {} other {with reminder after {reminderInterval} {reminderPeriod}}}',
    description: '[ScheduledCommunications] Send Date Label',
    id: 'scheduled.communications.send.date.label.entries.label',
  },
  cookiesPolicyHeader: {
    defaultMessage: 'MentorcliQ Cookies Notice',
    description: '[CookiesPolicy] Page Heading',
    id: 'cookies.policy.page.heading',
  },
  cookiesPolicyOverallInfo: {
    defaultMessage:
      'MentorcliQ may use cookies and other tracking technologies to collect and store information about your interactions with the Services. Cookies are small text files that a website stores on your device that can capture and recall certain information to enable the recognition of your device and your preferences.',
    description: '[CookiesPolicy] Overall info text',
    id: 'cookies.policy.overall.info.text',
  },
  cookiesPolicyCookiesFor: {
    defaultMessage: '<strong>What do we use Cookies for?</strong>',
    description: '[CookiesPolicy] Cookies for label',
    id: 'cookies.policy.cookies.for.label',
  },
  cookiesPolicyNecessaryCookies: {
    defaultMessage:
      '<u>Strictly Necessary Cookies (Essential)</u>{br}Strictly Necessary Cookie should be enabled at all times so that we can save your preferences for cookie settings.',
    description: '[CookiesPolicy] Necessary cookies text',
    id: 'cookies.policy.necessary.cookies.text',
  },
  cookiesPolicyNonNecessaryCookies: {
    defaultMessage:
      '<u>Non-necessary Cookies (Functional and Advertising)</u>{br}This website uses Google Analytics Cookies to collect anonymous information such as the number of visitors to the site, and the most popular pages. Keeping these cookies enabled helps us to improve our website.',
    description: '[CookiesPolicy] Non necessary cookies text',
    id: 'cookies.policy.non.necessary.cookies.text',
  },
  cookiesPolicyManagingCookiePreferencesLabel: {
    defaultMessage: '<strong>Managing Cookie Preferences</strong>',
    description: '[CookiesPolicy] Managing Cookie Preferences label',
    id: 'cookies.policy.manage.cookie.preferences.label',
  },
  cookiesPolicyManagingCookiePreferencesText: {
    defaultMessage:
      'If you do not want to have Non-necessary Cookies placed on your device, you can select the “Reject all” option on the Cookie Banner upon first login, which can be found at the footer of your screen or by clicking the Manage Cookie Preferences button, which can be found in the form of a shield at the bottom of any subsequent screen.',
    description: '[CookiesPolicy] Managing Cookie Preferences text',
    id: 'cookies.policy.manage.cookie.preferences.text',
  },
  cookiesPolicyComplaintsLabel: {
    defaultMessage: '<strong>Complaints</strong>',
    description: '[CookiesPolicy] Complaints label',
    id: 'cookies.policy.complaints.label',
  },
  cookiesPolicyComplaintsText: {
    defaultMessage:
      'You have the right to lodge a complaint with a supervisory authority. Learn more about how to contact your supervisory authority in the European Economic Area and in the United Kingdom.',
    description: '[CookiesPolicy] Complaints text',
    id: 'cookies.policy.complaints.text',
  },

  cookiesPolicyChangesLabel: {
    defaultMessage: '<strong>Changes</strong>',
    description: '[CookiesPolicy] Changes label',
    id: 'cookies.policy.changes.label',
  },
  cookiesPolicyChangesText: {
    defaultMessage:
      'MentorcliQ may modify or supplement this Cookies Notice from time to time, in which case we will post the updated Cookie Notice here and change the “last updated” date at the top of this page.',
    description: '[CookiesPolicy] Changes text',
    id: 'cookies.policy.changes.text',
  },
  cookiesPolicyContactUsLabel: {
    defaultMessage: '<strong>Contact Us</strong>',
    description: '[CookiesPolicy] Contact us label',
    id: 'cookies.policy.contact.us.label',
  },
  cookiesPolicyContactUsText: {
    defaultMessage:
      'If you have any questions about this Cookies Notice, please contact us by email at privacy@MentorcliQ.com or write to us at:',
    description: '[CookiesPolicy] Contact us text',
    id: 'cookies.policy.contact.us.text',
  },
  cookiesPolicyContactUsAddress: {
    defaultMessage:
      'Privacy Officer{br}MentorcliQ, Inc.{br}445 N High Street 3rd Floor{br}Columbus, OH 43215{br}United States',
    description: '[CookiesPolicy] Contact us address text',
    id: 'cookies.policy.contact.us.address',
  },
  downloadCSVButtonLabel: {
    defaultMessage: 'Download as CSV',
    description: '[Global] Download button',
    id: 'global.download.csv.label',
  },
  intelligentMatchingMaxWeekly: {
    defaultMessage: 'For weekly period maximum value for the interval must be {val} (1 year)',
    description: '[Validation]',
    id: 'intelligent.matching.max.weekly',
  },
  intelligentMatchingMaxMonthly: {
    defaultMessage: 'For monthly period maximum value for the interval must be {val} (1 year)',
    description: '[Validation]',
    id: 'intelligent.matching.max.monthly',
  },
  participantsEntriesLabel: {
    defaultMessage: 'participants',
    description: '[Global] Participants Entry label',
    id: 'global.participants.label',
  },
  matchesEntriesLabel: {
    defaultMessage: 'matches',
    description: '[Global] Matches Entry label',
    id: 'global.matches.label',
  },
  milestonesEntriesLabel: {
    defaultMessage: 'milestones',
    description: '[Global] Milestones Entry label',
    id: 'global.milestones.label',
  },
  relationshipsEntriesLabel: {
    defaultMessage: 'relationships',
    description: '[Global] Relationships Entry label',
    id: 'global.relationships.label',
  },
  invitationsEntriesLabel: {
    defaultMessage: 'invitations',
    description: '[Global] Invitations Entry label',
    id: 'global.invitations.label',
  },
  groupsEntriesLabel: {
    defaultMessage: 'groups',
    description: '[Global] Groups Entry label',
    id: 'global.groups.label',
  },
  candidatesEntriesLabel: {
    defaultMessage: 'candidates',
    description: '[Global] Candidates Entry label',
    id: 'global.candidates.label',
  },
  noCandidatesAvailable: {
    defaultMessage: 'You have no candidates available yet.',
    description: '[Global] No candidates available label',
    id: 'global.no.candidates.label',
  },
  resourcesEntriesLabel: {
    defaultMessage: 'resources',
    description: '[Global] Resources Entry label',
    id: 'global.resources.label',
  },
  groupMatchMatchingRulesCriteriaNote: {
    defaultMessage:
      'Matching rules are applied when loading the enrollments below based on the existing group members. Adding multiple enrollments to a group at one time might result in a case where the new enrollments being added don’t meet all of the matching rule criteria. To ensure matching rules are applied for every addition to a group, we recommend adding a single enrollment to a group at a time.',
    description: '[GroupCart] Matching Rules criteria note',
    id: 'group.cart.matching.rules.note',
  },
  overallMatchInfoModalTitle: {
    defaultMessage: 'Match Info',
    description: '[MatchInfo] modal title',
    id: 'global.match.info.modal.title',
  },
  emptyListDefaultLabel: {
    defaultMessage: 'data',
    description: '[Global] Empty list data label',
    id: 'global.empty.list.data.label',
  },
  emptyListDefaultContent: {
    defaultMessage: 'No data exists for this table yet. Please come back later.',
    description: '[Global] Empty data table text',
    id: 'global.empty.data.table.text',
  },
  inviteRole: {
    defaultMessage: 'Invite {roleName}',
    description: '[Global] Invite role label',
    id: 'global.invite.role',
  },
  clearAll: {
    defaultMessage: 'Clear all',
    description: '[Global] Clear all label',
    id: 'global.clear.all',
  },
  filters: {
    defaultMessage: 'Filters',
    description: '[Global] Filters label',
    id: 'global.filters',
  },
  moreFilters: {
    defaultMessage: 'More Filters',
    description: '[Global] More filters label',
    id: 'global.more.filters',
  },
  noFiltersApplied: {
    defaultMessage: 'No filters applied.',
    description: '[Global] No filters applied label',
    id: 'global.no.filters.applied',
  },
  globalYesLabel: {
    defaultMessage: 'Yes',
    description: '[Global] Yes label',
    id: 'global.yes.label',
  },
  globalNoLabel: {
    defaultMessage: 'No',
    description: '[Global] No label',
    id: 'global.no.label',
  },
  globalFromLabel: {
    defaultMessage: 'From',
    description: '[Global] From label',
    id: 'global.from.label',
  },
  globalToLabel: {
    defaultMessage: 'To',
    description: '[Global] To label',
    id: 'global.to.label',
  },
  globalOrSeparator: {
    defaultMessage: 'OR',
    description: '[Global] Or separator',
    id: 'global.or.separator',
  },
  globalAvailableOptions: {
    defaultMessage: 'Available Options',
    description: '[Global] Available options text',
    id: 'global.available.option',
  },
  globalAddOptions: {
    defaultMessage: 'Add Option',
    description: '[Global] Add option text',
    id: 'global.add.option',
  },
  globalBalkAddOptions: {
    defaultMessage: 'Add bulk options as plain text',
    description: '[Global] Bulk Add options text',
    id: 'global.bulk.add.option',
  },
  groupStatusFilterLabel: {
    defaultMessage: 'Group Status',
    description: '[Reports] filters match statuses label',
    id: 'reports.filters.group.status.label',
  },
  groupStatusFilterTagLabel: {
    defaultMessage: 'Group Status: {groupStatus}',
    description: '[Reports] filters match statuses label',
    id: 'reports.filters.group.status.tag.label',
  },
  addBulkFocusAreasLabel: {
    defaultMessage: 'Add Bulk Focus Areas',
    description: '[ProgramWizard] add bulk focus areas label',
    id: 'program.wizard.bulk.add.competencies.label',
  },
  addCategory: {
    defaultMessage: 'Add Category',
    description: '[Global] Add Category label',
    id: 'global.add.category.label',
  },
  addChild: {
    defaultMessage: 'Add Child',
    description: '[Global] Add Child label',
    id: 'global.add.child.label',
  },
  addFocusArea: {
    defaultMessage: 'Add Focus Area',
    description: '[Global] Add Focus Area label',
    id: 'global.add.competency.label',
  },
  globalMe: {
    defaultMessage: 'Me',
    description: '[Global] Me text',
    id: 'global.me',
  },
  globalNew: {
    defaultMessage: 'NEW',
    description: '[Global] New text',
    id: 'global.new',
  },
  programSettingsLabel: {
    defaultMessage: 'Program Settings',
    description: '[ProgramSettings] label',
    id: 'program.settings.label',
  },
  programSettingsSpecificLabel: {
    defaultMessage: 'Program Settings - {label}',
    description: '[ProgramSettings] specific label',
    id: 'program.settings.specific.label',
  },
  sendQuickcliQsLabel: {
    defaultMessage: 'Send QuickcliQs',
    description: '[ProgramSettings] Send QuickcliQs label',
    id: 'program.settings.send.quickcliqs.label',
  },
  promptProfileEnrollmentUpdateLabel: {
    defaultMessage: 'Prompt Profile/Enrollment Update',
    description: '[ProgramSettings] Prompt Profile/Enrollment Update label',
    id: 'program.settings.prompt.profile.enrollment.update.label',
  },
  promptProfileUpdateLabel: {
    defaultMessage: 'Profile',
    description: '[ProgramSettings] Prompt Profile Update label',
    id: 'program.settings.prompt.profile.update.label',
  },
  promptEnrollmentUpdateLabel: {
    defaultMessage: 'Enrollment',
    description: '[ProgramSettings] Prompt Enrollment Update label',
    id: 'program.settings.prompt.enrollment.update.label',
  },
  lastUpdatedColumnLabel: {
    defaultMessage: 'Last Updated',
    description: '[TableColumn] column name',
    id: 'table.column.last.updated.label',
  },
  userColumnLabel: {
    defaultMessage: 'User',
    description: '[TableColumn] column name',
    id: 'table.column.user.label',
  },
  lastPromptRequestDateColumnLabel: {
    defaultMessage: 'Last Prompt Request Date',
    description: '[TableColumn] column name',
    id: 'table.column.last.request.date.label',
  },
  promptStatusColumnLabel: {
    defaultMessage: 'Prompt Status',
    description: '[TableColumn] column name',
    id: 'table.column.prompt.status.label',
  },
  statusColumnLabel: {
    defaultMessage: 'Status',
    description: '[TableColumn] column name',
    id: 'table.column.status.label',
  },
  roleColumnLabel: {
    defaultMessage: 'Role',
    description: '[TableColumn] column name',
    id: 'table.column.role.label',
  },
  globalTimeoutErrorTitle: {
    defaultMessage: 'Mentoring in High Demand — Hold Tight!',
    description: '[ApiTimeoutModal] Title',
    id: 'public.api.timeout.modal.title',
  },
  globalTimeoutErrorDescription: {
    defaultMessage:
      'Your platform is experiencing high mentoring demand right now. Sorry for the delay and thank you for your patience! Please try again shortly—mentoring is just around the corner!',
    description: '[ApiTimeoutModal] Description',
    id: 'public.api.timeout.modal.description',
  },
  matchingAlgorithmValueLabel: {
    defaultMessage: 'Value Label',
    description: '[MatchingAlgorithm] value label',
    id: 'matching.algorithm.value.label',
  },
  matchingAlgorithmValueDescription: {
    defaultMessage: 'Displayed on the View Details screen/page.',
    description: '[MatchingAlgorithm] value description',
    id: 'matching.algorithm.value.description',
  },
  matchingStyleGroup: {
    defaultMessage: 'Group',
    description: '[MatchingStyle] Group match label',
    id: 'matching.style.group.label',
  },
  matchingStyleOneToOne: {
    defaultMessage: 'One To One',
    description: '[MatchingStyle] One To One match label',
    id: 'matching.style.one.to.one.label',
  },
  globalInviteParticipantsLabel: {
    defaultMessage: 'Invite Participants',
    description: '[Global] Invite participants label',
    id: 'global.invite.participants.label',
  },
  next: {
    defaultMessage: 'Next',
    description: '[Global] Next label',
    id: 'global.next.label',
  },
  back: {
    defaultMessage: 'Back',
    description: '[Global] Back label',
    id: 'global.back.label',
  },
  continue: {
    defaultMessage: 'Continue',
    description: '[Global] Continue label',
    id: 'global.continue.label',
  },
  subject: {
    defaultMessage: 'Subject',
    description: '[Global] Subject label',
    id: 'global.subject.label',
  },
  message: {
    defaultMessage: 'Message',
    description: '[Global] Message label',
    id: 'global.message.label',
  },
  qhrXCsvLabel: {
    defaultMessage: 'QHRx: {bucketName} - {fileName}',
    description: '[QHRx] CSV label',
    id: 'qhrx.csv.label',
  },
  actionCannotBeUndone: {
    defaultMessage: 'This action cannot be undone.',
    description: '[Global] Action cannot be undone message',
    id: 'global.action.cannot.be.undone',
  },
  reviseMessage: {
    defaultMessage: 'Revise Message',
    description: '[Global] Revise Message label',
    id: 'global.revise.message.label',
  },
  messagePreview: {
    defaultMessage: 'Message Preview',
    description: '[Global] Message Preview label',
    id: 'global.message.preview.label',
  },
  composeMessage: {
    defaultMessage: 'Compose Message',
    description: '[Global] Compose Message label',
    id: 'global.compose.message.label',
  },
  selectedAllParticipants: {
    defaultMessage: 'You selected all {totalElements} participants.',
    description: '[Global] Selected all participants text',
    id: 'global.selected.all.participants.text',
  },
  selectedFollowingParticipants: {
    defaultMessage: 'You selected the following participant(s):',
    description: '[Global] Selected following participants text',
    id: 'global.selected.following.participants.text',
  },
  sendCommunications: {
    defaultMessage: 'Send Communications',
    description: '[Global] Send Communications label',
    id: 'global.send.communications.label',
  },
  noMatchesTitle: {
    defaultMessage: 'No matches exist',
    description: '[Global] No matches exists empty list title',
    id: 'global.no.matches.title',
  },
  noMatchesContent: {
    defaultMessage:
      'Your program currently has no matches. Start matching your mentors and mentees together by running smart match or manually matching your participants.',
    description: '[Global] No matches exists empty list content',
    id: 'global.no.matches.content',
  },
  smartMatch: {
    defaultMessage: 'Smart Match',
    description: '[Global] Smart Match label',
    id: 'global.smart.match',
  },
  groupMatch: {
    defaultMessage: 'Group Match',
    description: '[Global] Group Match label',
    id: 'global.group.match',
  },
  manualMatch: {
    defaultMessage: 'Manual Match',
    description: '[Global] Manual Match label',
    id: 'global.manual.match',
  },
  selectedParticipantsAbove: {
    defaultMessage: 'Selected Participants Above',
    description: '[Global] Selected Participants Above label',
    id: 'global.selected.participants.above',
  },
  messageToLabel: {
    defaultMessage: 'Message to the {roleName}',
    description: '[Global] Message to label',
    id: 'global.message.to.label',
  },
  globalAllLabel: {
    defaultMessage: 'All',
    description: '[Global] All label',
    id: 'global.all.label',
  },
  smartMatchWaitingStatus: {
    defaultMessage: 'Waiting...',
    description: '[Global] Smart match waiting status',
    id: 'global.smart.match.waiting',
  },
  smartMatchDoneStatus: {
    defaultMessage: 'Done!',
    description: '[Global] Smart match done status',
    id: 'global.smart.match.done',
  },
  smartMatchProcessingStatus: {
    defaultMessage: 'Processing...',
    description: '[Global] Smart match processing status',
    id: 'global.smart.match.processing',
  },
  updateIntelligentMatch: {
    defaultMessage: 'Update Intelligent Match',
    description: '[Global] Update Intelligent Match button label',
    id: 'global.update.intelligent.match',
  },
  scheduleIntelligentMatch: {
    defaultMessage: 'Schedule Intelligent Match',
    description: '[Global] Update Intelligent Match button label',
    id: 'global.schedule.intelligent.match',
  },
  statusLabel: {
    defaultMessage: 'Status',
    description: '[Global] Status label',
    id: 'global.status.label',
  },
  statusTagLabel: {
    defaultMessage: 'Status: {label}',
    description: '[Global] Status tag label',
    id: 'global.status.tag.label',
  },
  continueEditing: {
    defaultMessage: 'Continue editing',
    description: '[Global] Continue editing label',
    id: 'global.continue.editing.label',
  },
  resetToDefault: {
    defaultMessage: 'Reset to Default',
    description: '[Global] Reset to Default label',
    id: 'global.reset.to.default',
  },
  successLabel: {
    defaultMessage: 'Success',
    description: '[Global] Success label',
    id: 'global.success.label',
  },
  failureLabel: {
    defaultMessage: 'Failure',
    description: '[Global] Failure label',
    id: 'global.failure.label',
  },
  suggestResource: {
    defaultMessage: 'Suggest Resource',
    description: '[Global] Suggest Resource label',
    id: 'global.suggest.resource.label',
  },
  notApplicableLabel: {
    defaultMessage: 'N/A',
    description: '[Global] Not applicable label',
    id: 'global.not.applicable.label',
  },
  promptedProfilesAlertMessage: {
    defaultMessage: 'Processed request for {count} completed profile(s)',
    description: '[PromptProfiles] processed profiles message',
    id: 'prompt.profiles.processed.message',
  },
  promptedProfilesRequirementsMessage: {
    defaultMessage: 'Please select at least 1 completed profile?',
    description: '[PromptProfiles] processed enrollments message',
    id: 'prompt.profiles.requirements.message',
  },
  promptedEnrollmentsAlertMessage: {
    defaultMessage: 'Processed request for {count} completed enrollment(s)',
    description: '[PromptEnrollment] processed enrollments message',
    id: 'prompt.enrollments.processed.message',
  },
  promptedEnrollmentsRequirementsMessage: {
    defaultMessage: 'Please select at least 1 completed enrollment?',
    description: '[PromptEnrollment] processed enrollments message',
    id: 'prompt.enrollments.requirements.message',
  },
  enrollmentsPromptModalTitle: {
    defaultMessage: 'Do you want to prompt a enrollment update from users?',
    description: '[PromptEnrollment] Prompt modal title',
    id: 'enrollments.prompt.modal.title',
  },
  enrollmentsPromptCountInfo: {
    defaultMessage:
      'Prompting enrollment updates for <strong>{count}</strong> completed enrollments out of <strong>{total}</strong>',
    description: '[PromptEnrollments] counts info note',
    id: 'prompt.enrollments.counts.info.note',
  },
  enrollmentsPromptNote: {
    defaultMessage: 'This action will prompt the user to review and update their enrollment at next login.',
    description: '[PromptEnrollments] note',
    id: 'prompt.enrollments.prompt.note',
  },
  profilesPromptModalTitle: {
    defaultMessage: 'Do you want to prompt a profile update from users?',
    description: '[PromptProfiles] Prompt modal title',
    id: 'profiles.prompt.modal.title',
  },
  profilesPromptCountInfo: {
    defaultMessage:
      'Prompting profile updates for <strong>{count}</strong> completed profiles out of <strong>{total}</strong>',
    description: '[PromptProfiles] counts info note',
    id: 'prompt.profiles.counts.info.note',
  },
  profilesPromptNote: {
    defaultMessage: 'This action will prompt the user to review and update their profiles at next login.',
    description: '[PromptProfiles] note',
    id: 'prompt.profiles.prompt.note',
  },
  promptProfilesEntriesLabel: {
    defaultMessage: 'Profiles',
    description: '[PromptProfiles] profile entries',
    id: 'prompt.profiles.entries.label',
  },
  promptEnrollmentsEntriesLabel: {
    defaultMessage: 'Enrollments',
    description: '[PromptEnrollments] enrollments entries',
    id: 'prompt.enrollments.entries.label',
  },
  mostCommonLabel: {
    defaultMessage: 'This is the most common.',
    description: '[Global] This is the most common label',
    id: 'global.most.common',
  },
  stepOneOfTwoLabel: {
    defaultMessage: 'Step 1 of 2',
    description: '[Global] Step 1 of 2 label',
    id: 'global.step.1.of.2',
  },
  stepTwoOfTwoLabel: {
    defaultMessage: 'Step 2 of 2',
    description: '[Global] Step 2 of 2 label',
    id: 'global.step.2.of.2',
  },
  stepOneOfThreeLabel: {
    defaultMessage: 'Step 1 of 3',
    description: '[Global] Step 1 of 3 label',
    id: 'global.step.1.of.3',
  },
  stepTwoOfThreeLabel: {
    defaultMessage: 'Step 2 of 3',
    description: '[Global] Step 2 of 3 label',
    id: 'global.step.2.of.3',
  },
  stepThreeOfThreeLabel: {
    defaultMessage: 'Step 3 of 3',
    description: '[Global] Step 3 of 3 label',
    id: 'global.step.3.of.3',
  },
  addProgramLabel: {
    defaultMessage: 'Add a Program',
    description: '[Global] Add a Program label',
    id: 'global.add.program.label',
  },
  editField: {
    defaultMessage: 'Edit field',
    description: '[Global] Edit field label',
    id: 'global.edit.field.label',
  },
  configureProgram: {
    defaultMessage: 'Configure Program',
    description: '[Global] Configure Program label',
    id: 'global.configure.program.label',
  },
  emailSubjectLabel: {
    defaultMessage: 'E-mail Subject',
    description: '[Global] E-mail Subject label',
    id: 'global.email.subject.label',
  },
  emailMessageLabel: {
    defaultMessage: 'E-mail Message',
    description: '[Global] E-mail Message label',
    id: 'global.email.message.label',
  },
  addQuestion: {
    defaultMessage: 'Add Question',
    description: '[Global] Add Question label',
    id: 'global.add.question.label',
  },
  edit: {
    defaultMessage: 'Edit',
    description: '[Global] Edit label',
    id: 'global.edit.label',
  },
  clickButtonToUpdate: {
    defaultMessage: 'Click the button to update',
    description: '[Global] Click to button update label',
    id: 'global.click.button.to.update',
  },
  appHeaderMyCliQ: {
    defaultMessage: 'My CliQ',
    description: '[Header] My CliQ',
    id: 'app.header.my.cliq',
  },
  appHeaderLearningLab: {
    defaultMessage: 'LearningLAB',
    description: '[Header] learning lab',
    id: 'app.header.learning.lab',
  },
  appHeaderMentorLab: {
    defaultMessage: 'MentorLAB',
    description: '[Header] mentor lab',
    id: 'app.header.mentor.lab',
  },
  appHeaderInclusionLab: {
    defaultMessage: 'InclusionLAB',
    description: '[Header] inclusion lab',
    id: 'app.header.inclusion.lab',
  },
  appHeaderLeadershipLab: {
    defaultMessage: 'LeadershipLAB',
    description: '[Header] LeadershipLAB lab',
    id: 'app.header.leadership.lab',
  },
  learningLabSessionLabel: {
    defaultMessage: 'Session',
    description: '[LearningLab] session label',
    id: 'learning.lab.session.label',
  },
  mentorLabLabel: {
    defaultMessage: 'MentorLab',
    description: '[Global] MentorLab label',
    id: 'global.mentorlab.label',
  },
  inclusionLabLabel: {
    defaultMessage: 'InclusionLab',
    description: '[Global] InclusionLab label',
    id: 'global.inclusionlab.label',
  },
  leadershipLabLabel: {
    defaultMessage: 'LeadershipLab',
    description: '[Global] LeadershipLab label',
    id: 'global.leadershiplab.label',
  },
  masterClassLabel: {
    defaultMessage: 'Master Class',
    description: '[Global] Master Class label',
    id: 'global.master.class.label',
  },
  quickStartLabel: {
    defaultMessage: 'Quick Start',
    description: '[Global] Quick Start label',
    id: 'global.quick.start.label',
  },
  appHeaderResourceLibrary: {
    defaultMessage: 'Resource Library',
    description: '[Header] Resource Library',
    id: 'app.header.resource.library',
  },
  appHeaderMyProfile: {
    defaultMessage: 'My Profile',
    description: '[Header] My Profile',
    id: 'app.header.my.profile',
  },
  appHeaderSupport: {
    defaultMessage: 'Support',
    description: '[Header] Support',
    id: 'app.header.support',
  },
  appHeaderPrivacy: {
    defaultMessage: 'Privacy',
    description: '[Header] Privacy',
    id: 'app.header.privacy',
  },
  appHeaderLogout: {
    defaultMessage: 'Log Out',
    description: '[Header] Log Out',
    id: 'app.header.logout',
  },
  globalParticipantView: {
    defaultMessage: 'Participant View',
    description: '[Global] Participant View',
    id: 'global.participant.view',
  },
  globalAdminView: {
    defaultMessage: 'Admin View',
    description: '[Global] Participant View',
    id: 'global.admin.view',
  },
  searchUsersLabel: {
    defaultMessage: 'Search for user by name/email',
    description: '[Global] Search users label',
    id: 'global.search.users.label',
  },
  createNewProgramAdminLabel: {
    defaultMessage: 'Create Program Admin',
    description: '[ProgramWizard] Create Program Admin label',
    id: 'program.wizard.create.program.admin.label',
  },
  developingOthersReportLabel: {
    defaultMessage: 'Developing Others Report',
    description: '[DevelopingReport] Other label',
    id: 'developing.others.report.label',
  },
  developingSelfReportLabel: {
    defaultMessage: 'Developing Self Report',
    description: '[DevelopingReport] Self label',
    id: 'developing.self.report.label',
  },
  editProgramAdminLabel: {
    defaultMessage: 'Edit Program Admin',
    description: '[ProgramWizard] Edit admin modal title',
    id: 'program.wizard.admins.edit.admin.label',
  },
  addProgramAdminLabel: {
    defaultMessage: 'Add Program Admin',
    description: '[ProgramWizard] Add admin modal title',
    id: 'program.wizard.admins.add.admin.label',
  },
  updateProgramAdminLabel: {
    defaultMessage: 'Update Program Admin',
    description: '[ProgramWizard] Update admin modal title',
    id: 'program.wizard.admins.update.admin.label',
  },
  programAdminAlreadyExists: {
    defaultMessage: 'Program Admin with this Email already exists.',
    description: '[Validation] Program Admin already exists message',
    id: 'program.validation.admin.already.exists.message',
  },
  createNewProgramSponsorLabel: {
    defaultMessage: 'Create Program Sponsor',
    description: '[ProgramWizard] Create Program Sponsor label',
    id: 'program.wizard.create.program.sponsor.label',
  },
  addProgramSponsors: {
    defaultMessage: 'Add Program Sponsors',
    description: '[ProgramWizard] Add Program Sponsors label',
    id: 'program.wizard.add.program.sponsors.label',
  },
  programSponsorAlreadyExists: {
    defaultMessage: 'Program Sponsor with this Email already exists.',
    description: '[Validation] Program Sponsor already exists message',
    id: 'program.validation.sponsor.already.exists.message',
  },
  optionsLabel: {
    defaultMessage: 'Options',
    description: '[Global] Options label',
    id: 'global.options.label',
  },
  globalField: {
    defaultMessage: 'Field',
    description: '[Global] Field label',
    id: 'global.field.label',
  },
  globalLabel: {
    defaultMessage: 'Label',
    description: '[Global] Label label',
    id: 'global.label.label',
  },
  globalTitle: {
    defaultMessage: 'Title',
    description: '[Global] Title label',
    id: 'global.title.label',
  },
  clickForMoreDetails: {
    defaultMessage: 'Click for more details...',
    description: '[Global] Click for more details label',
    id: 'global.click.for.more.details',
  },
  emailSubjectRequester: {
    defaultMessage: 'E-mail Subject Requester',
    description: '[Global] E-mail Subject Requester field value',
    id: 'global.email.subject.requester',
  },
  emailMessageRequester: {
    defaultMessage: 'E-mail Message Requester',
    description: '[Global] E-mail Message Requester field value',
    id: 'global.email.message.requester',
  },
  emailSubjectRequestee: {
    defaultMessage: 'E-mail Subject Requestee',
    description: '[Global] E-mail Subject Requestee field value',
    id: 'global.email.subject.requestee',
  },
  emailMessageRequestee: {
    defaultMessage: 'E-mail Message Requestee',
    description: '[Global] E-mail Message Requestee field value',
    id: 'global.email.message.requestee',
  },
  previewMessage: {
    defaultMessage: 'Preview Message',
    description: '[Global] Preview Message label',
    id: 'global.preview.message',
  },
  preview: {
    defaultMessage: 'Preview',
    description: '[Global] Preview label',
    id: 'global.preview.label',
  },
  scheduleMessage: {
    defaultMessage: 'Schedule Message',
    description: '[Global] Schedule Message label',
    id: 'global.schedule.message',
  },
  relationshipClosureDate: {
    defaultMessage: 'Relationship Closure Date',
    description: '[Global] Relationship Closure Date label',
    id: 'global.relationship.closure.date',
  },
  recipients: {
    defaultMessage: 'Recipients',
    description: '[Global] Recipients label',
    id: 'global.recipients.label',
  },
  sendReminderAfter: {
    defaultMessage: 'Send Reminder After',
    description: '[Global] Send Reminder After label',
    id: 'global.send.reminder.after',
  },
  retireProgram: {
    defaultMessage: 'Retire Program',
    description: '[Global] Retire Program label',
    id: 'global.retire.program.label',
  },
  profilePicture: {
    defaultMessage: 'Profile picture',
    description: '[Global] Profile picture label',
    id: 'global.profile.picture.label',
  },
  people: {
    defaultMessage: 'People',
    description: '[Global] People label',
    id: 'global.people.label',
  },
  saveAndContinue: {
    defaultMessage: 'Save & Continue',
    description: '[Global] Save & Continue label',
    id: 'global.save.and.continue.label',
  },
  programDescription: {
    defaultMessage: 'Program Description',
    description: '[Global] Program Description label',
    id: 'global.program.description.label',
  },
  adminDashboard: {
    defaultMessage: 'Admin Dashboard',
    description: '[Global] Admin Dashboard label',
    id: 'global.admin.dashboard.label',
  },
  viewDetails: {
    defaultMessage: 'View Details',
    description: '[Global] View Details label',
    id: 'global.view.details.label',
  },
  viewDetailsWithScore: {
    defaultMessage: 'View Details ({score})',
    description: '[Global] View Details with score label',
    id: 'global.view.details.score.label',
  },
  addOnsLabel: {
    defaultMessage: 'Add-Ons',
    description: '[Global] Add-Ons label',
    id: 'global.addons.label',
  },
  accountStatus: {
    defaultMessage: 'Account Status',
    description: '[Global] Account Status label',
    id: 'global.account.status',
  },
  changeUserRoles: {
    defaultMessage: 'Change User Roles',
    description: '[Global] Change User Roles label',
    id: 'global.change.user.roles',
  },
  sendMatchRequest: {
    defaultMessage: 'Send Match Request',
    description: '[Global] Send Match Request label',
    id: 'global.send.match.request',
  },
  skipThisStep: {
    defaultMessage: 'Skip this step',
    description: '[Global] Skip this step label',
    id: 'global.skip.this.step',
  },
  passwordMinimumOf8Characters: {
    defaultMessage: 'The password must have a minimum of 8 characters.',
    description: '[Global] The password must have a minimum of 8 characters text',
    id: 'global.password.must.have.minimum.8.characters',
  },
  firstNameLabel: {
    defaultMessage: 'First Name',
    description: '[Global] First name label',
    id: 'global.first.name.label',
  },
  lastNameLabel: {
    defaultMessage: 'Last Name',
    description: '[Global] Last name label',
    id: 'global.last.name.label',
  },
  nameLabel: {
    defaultMessage: 'Name',
    description: '[Global] name label',
    id: 'global.name.label',
  },
  primaryContactLabel: {
    defaultMessage: 'Primary Contact',
    description: '[Global] Primary contact label',
    id: 'global.primary.contact.label',
  },
  manageColumnLabel: {
    defaultMessage: 'Manage',
    description: '[Global] Manage column label',
    id: 'global.manage.column.label',
  },
  emailColumnLabel: {
    defaultMessage: 'E-mail',
    description: '[Global] Email column label',
    id: 'global.email.column.label',
  },
  nameColumnLabel: {
    defaultMessage: 'Name',
    description: '[Global] Name column label',
    id: 'global.name.column.label',
  },
  userRolesLabel: {
    defaultMessage: 'User Roles',
    description: '[Global] User roles label',
    id: 'global.user.roles.label',
  },
  minRoleShouldBeSelected: {
    defaultMessage: 'At least {min} role should be added',
    description: '[Validation] message',
    id: 'public.validation.min.roles.should.be.selected',
  },
  programWizardNewUserSelectionNote: {
    defaultMessage:
      '<strong>{email}</strong> is not currently enrolled. By selecting this user, they will be invited into the platform via email.',
    description: '[ProgramWizard] Admin form description',
    id: 'program.wizard.new.user.selection.note',
  },
  roiMultiplyMetricValue: {
    defaultMessage: '<strong>{value}</strong> X',
    description: '[ROIInvestmentReturn] Multiple return count',
    id: 'roi.multiple.metric.value',
  },
  configurationLabel: {
    defaultMessage: 'Configuration',
    description: '[Validation] Configuration label',
    id: 'global.configuration.label',
  },
  usageLabel: {
    defaultMessage: 'Usage',
    description: '[Validation] Usage label',
    id: 'global.usage.label',
  },
  survey: {
    defaultMessage: 'Survey',
    description: '[Global] Survey label',
    id: 'global.surveys.label',
  },
  programSurveys: {
    defaultMessage: 'Program Surveys',
    description: '[Global] Program Surveys label',
    id: 'global.program.surveys.label',
  },
  relationshipSurveys: {
    defaultMessage: 'Relationship Surveys',
    description: '[Global] Relationship Surveys label',
    id: 'global.relationship.surveys.label',
  },
  deleteDraft: {
    defaultMessage: 'Delete Draft',
    description: '[Global] Delete Draft label',
    id: 'global.delete.draft.label',
  },
  keywordSearchTag: {
    defaultMessage: 'Keyword search: {keyword}',
    description: '[Global] Keyword search tag',
    id: 'global.keyword.search.tag',
  },
  roleTag: {
    defaultMessage: 'Role: {role}',
    description: '[Global] Role tag',
    id: 'global.role.tag',
  },
  statusTag: {
    defaultMessage: 'Status: {status}',
    description: '[Global] Status tag',
    id: 'global.status.tag',
  },
  includeSurvey: {
    defaultMessage: 'Survey: Include a survey with this message',
    description: '[Global] Include a survey label',
    id: 'global.include.survey.label',
  },
  programSurveysSwitchLabelHelpText: {
    defaultMessage: 'Each participant will receive a single survey regardless of the number of enrollments or matches.',
    description: '[Global] Program surveys switch label help text',
    id: 'global.program.surveys.switch.help.text',
  },
  sendReminder: {
    defaultMessage: 'Send a Reminder {helpText}',
    description: '[Global] Send a Reminder label',
    id: 'global.send.reminder.label',
  },
  sendReminderTooltip: {
    defaultMessage: 'Select when a reminder email should be sent if participants have not yet completed the survey.',
    description: '[Global] Send a Reminder tooltip',
    id: 'global.send.reminder.tooltip',
  },
  surveysIncluded: {
    defaultMessage: 'Includes Survey(s) - {surveys}',
    description: '[Global] Surveys Included text',
    id: 'global.surveys.included',
  },
  noSurveyIncluded: {
    defaultMessage: 'No Survey Included',
    description: '[Global] No Survey Included text',
    id: 'global.no.survey.included',
  },
  actionsLabel: {
    defaultMessage: 'Actions',
    description: '[Global] Actions label',
    id: 'global.actions.label',
  },
  importLabel: {
    defaultMessage: 'Import',
    description: '[Global] Import label',
    id: 'global.import.label',
  },
  importCommunicationSchedule: {
    defaultMessage: 'Import Communication Schedule',
    description: '[Global] Import Communication Schedule label',
    id: 'global.import.communication.schedule.label',
  },
  relationshipClosure: {
    defaultMessage: 'Relationship Closure',
    description: '[Global] Relationship Closure label',
    id: 'global.relationship.closure.label',
  },
  overviewAndRecommendation: {
    defaultMessage: 'Overview & Recommendation',
    description: '[Global] Overview & Recommendation label',
    id: 'global.overview.and.recommendation.label',
  },
  questionInfoModalInformationalText: {
    defaultMessage:
      "<strong>Informational:</strong> We'd typically include this information on the profilette of participants to help inform the admin and other participants about where this user is located within the business structure.",
    description: '[Global] Question info modal informational text',
    id: 'global.question.info.modal.informational.text',
  },
  questionInfoModalReportingText: {
    defaultMessage:
      '<strong>Reporting:</strong> Often used in the participant data export as well as in the MentorcliQ Program Reports > Participation report to show a summary of mentors/mentees within each department.',
    description: '[Global] Question info modal reporting text',
    id: 'global.question.info.modal.reporting.text',
  },
  contactYourClientPartner: {
    defaultMessage: 'Contact Your Client Partner',
    description: '[Global] Contact Your Client Partner label',
    id: 'global.contact.your.client.partner',
  },
  contentHelpdesk: {
    defaultMessage: 'Content Helpdesk',
    description: '[Global] Content Helpdesk label',
    id: 'global.content.helpdesk',
  },
  shareLinkOptionsLabel: {
    defaultMessage: 'Link Sharing Options',
    description: '[Global] Link sharing options label',
    id: 'global.sharing.link.options.label',
  },
  directLinkLabel: {
    defaultMessage: 'Direct Link',
    description: '[Global] Direct link label',
    id: 'global.direct.link.label',
  },
  embedLinkLabel: {
    defaultMessage: 'Embed Link',
    description: '[Global] Embed link label',
    id: 'global.embed.link.label',
  },
  embedLinkDescription: {
    defaultMessage: 'To use the link in the messages, copy the embedded link.',
    description: '[Global] Embed link description',
    id: 'global.embed.link.description',
  },
  globalBack: {
    defaultMessage: 'Back',
    description: '[Global] Back text',
    id: 'global.back',
  },
  globalNext: {
    defaultMessage: 'Next',
    description: '[Global] Next text',
    id: 'global.next',
  },
  globalSearchTerms: {
    defaultMessage: 'Search term(s)...',
    description: '[Global] Search terms',
    id: 'global.search.terms',
  },
  globalApply: {
    defaultMessage: 'Apply',
    description: '[Global] Apply',
    id: 'global.apply',
  },
  // TOKENS
  tokenEmbedAsLinkRestrictLabel: {
    defaultMessage: 'this token cannot be embedded as a link',
    description: '[Token] Token additional info',
    id: 'tokens.user.name.additional.info',
  },
  useNameTokenDescription: {
    defaultMessage: 'User name',
    description: '[Token] user_name Token description',
    id: 'TOKENS.user.name.description',
  },
  partnerNameTokenDescription: {
    defaultMessage: 'Token_description',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.partner.name.description',
  },
  partnerNamesTokenDescription: {
    defaultMessage: 'Partner names',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.partner.names.description',
  },
  participantTokenDescription: {
    defaultMessage: 'Token_description',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.participant.description',
  },
  surveyInboxTokenDescription: {
    defaultMessage: 'Survey inbox link',
    description: '[Token] surveyInbox Token description',
    id: 'TOKENS.survey.inbox.description',
  },
  leaveRelationshipTokenDescription: {
    defaultMessage: 'Leave relationship link',
    description: '[Token] leaveRelationship Token description',
    id: 'TOKENS.leave.relationship.description',
  },
  participantNameTokenDescription: {
    defaultMessage: 'Participant name',
    description: '[Token] participantName Token description',
    id: 'TOKENS.participant.name.description',
  },
  approvalLinkTokenDescription: {
    defaultMessage: 'Approval link',
    description: '[Token] approvalLink Token description',
    id: 'TOKENS.approval.link.description',
  },
  approvalDeclineMessageTokenDescription: {
    defaultMessage: 'Participant Declined Message',
    description: '[Token] approvalDeclineMessage Token description',
    id: 'TOKENS.approval.decline.message.description',
  },
  programNameTokenDescription: {
    defaultMessage: 'Program Name',
    description: '[Token] programName Token description',
    id: 'TOKENS.program.name.description',
  },
  blankTokenDescription: {
    defaultMessage: 'Token_description',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.program.name.html.escaped.description',
  },
  roleTitleTokenDescription: {
    defaultMessage: 'Role Title',
    description: '[Token] roleTitle Token description',
    id: 'TOKENS.role.title.description',
  },
  dueDateTokenDescription: {
    defaultMessage: 'Due date',
    description: '[Token] dueDate Token description',
    id: 'TOKENS.due.date.description',
  },
  surveyLinkTokenDescription: {
    defaultMessage: 'Survey link',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.survey.link.description',
  },
  circleNameTokenDescription: {
    defaultMessage: 'Circle name',
    description: '[Token] circleName Token description',
    id: 'TOKENS.circle.name.description',
  },
  profilePageLinkTokenDescription: {
    defaultMessage: 'Profile page link',
    description: '[Token] profileLink Token description',
    id: 'TOKENS.profile.link.description',
  },
  matchCompatibilityTokenDescription: {
    defaultMessage: 'Participant match comparison',
    description: '[Token] matchCompatibility Token description',
    id: 'TOKENS.match.compatibility.description',
  },
  matchCompatibilityTokenInfo: {
    defaultMessage:
      'For cases where the recipient is not in an active match, they’ll see: “There are currently no match details to display. Access My CliQ for more info.“',
    description: '[Token] matchCompatibility Token additional info',
    id: 'TOKENS.match.compatibility.additional.info',
  },
  matchCompatibilityDetailsTokenDescription: {
    defaultMessage: 'Participant match comparison.',
    description: '[Token] matchCompatibilityDetails Token description',
    id: 'TOKENS.match.compatibility.details.description',
  },
  requesterNameTokenDescription: {
    defaultMessage: 'Requester name',
    description: '[Token] requesterName Token description',
    id: 'TOKENS.requester.name.description',
  },
  requesteeNameTokenDescription: {
    defaultMessage: 'Requestee name',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.requestee.name.description',
  },
  messageToRequesteeTokenDescription: {
    defaultMessage: 'Message to requestee',
    description: '[Token] messageToRequestee Token description',
    id: 'TOKENS.message.to.requestee.description',
  },
  matchRequestExpiresDaysTokenDescription: {
    defaultMessage: 'Match request expire days',
    description: '[Token] matchRequestExpiresDays Token description',
    id: 'TOKENS.match.request.expires.days.description',
  },
  acceptLinkTokenDescription: {
    defaultMessage: 'Request accept link',
    description: '[Token] acceptLink Token description',
    id: 'TOKENS.accept.link.description',
  },
  declineLinkTokenDescription: {
    defaultMessage: 'Request decline link',
    description: '[Token] declineLink Token description',
    id: 'TOKENS.decline.link.description',
  },
  resourceLibraryTokenDescription: {
    defaultMessage: 'Resource Library page link',
    description: '[Token] resourceLibrary Token description',
    id: 'TOKENS.resource.library.description',
  },
  resourceLibraryPathTokenDescription: {
    defaultMessage: 'Resource Library path link',
    description: '[Token] resourceLibrary Path Token description',
    id: 'TOKENS.resource.library.path.description',
  },
  requestedNameTokenDescription: {
    defaultMessage: 'Requested name',
    description: '[Token] requested Token description',
    id: 'TOKENS.requested.description',
  },
  noteFromRequesterTokenDescription: {
    defaultMessage: 'Note from requested',
    description: '[Token] noteFromRequester Token description',
    id: 'TOKENS.note.from.requester.description',
  },
  requestAnotherMatchLinkTokenDescription: {
    defaultMessage: 'Request another match page link',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.request.another.match.link.description',
  },
  groupMatchDetailsTokenDescription: {
    defaultMessage: 'Group-specific match details',
    description: '[Token] groupMatchDetails Token description',
    id: 'TOKENS.group.match.details.description',
  },
  groupOtherMembersTokenDescription: {
    defaultMessage: "Group member's names",
    description: '[Token] groupOtherMembers Token description',
    id: 'TOKENS.group.other.members.description',
  },
  mentorcliqSurveyLinkTokenDescription: {
    defaultMessage: 'Survey (Link)',
    description: '[Token] mentorcliqSurveyLink Token description',
    id: 'TOKENS.mentorcliq.survey.link.description',
  },
  enrollmentLinkTokenDescription: {
    defaultMessage: 'Enrollment information page (Link)',
    description: '[Token] enrollmentLink Token description',
    id: 'TOKENS.enrollment.link.description',
  },
  milestoneLinkTokenDescription: {
    defaultMessage: 'Milestones page (Link)',
    description: '[Token] milestoneLink Token description',
    id: 'TOKENS.milestone.link.description',
  },
  developmentTimelineLinkTokenDescription: {
    defaultMessage: 'Development Timeline (Link)',
    description: '[Token] developmentTimelineLink Token description',
    id: 'TOKENS.development.timeline.link.description',
  },
  mentorcliqProgramNameTokenDescription: {
    defaultMessage: 'Program Name',
    description: '[Token] mentorcliqProgramName Token description',
    id: 'TOKENS.mentorcliq.program.name.description',
  },
  mentorcliqPlatformNameTokenDescription: {
    defaultMessage: 'Platform Name',
    description: '[Token] mentorcliqPlatformName Token description',
    id: 'TOKENS.mentorcliq.platform.name.description',
  },
  quickcliqPeriodSummaryTokenDescription: {
    defaultMessage: 'Quickcliq period summary',
    description: '[Token] TOKEN_LABEL Token description',
    id: 'TOKENS.quickcliq.period.summary.description',
  },
  mentorcliqQuickcliqLinkTokenDescription: {
    defaultMessage: 'Quickcliq page (Link)',
    description: '[Token] mentorcliqQuickcliqLink Token description',
    id: 'TOKENS.mentorcliq.quickcliq.link.description',
  },
  programDescriptionTokenDescription: {
    defaultMessage: 'Program Description',
    description: '[Token] programDescription Token description',
    id: 'TOKENS.program.description.description',
  },
  intervalTokenDescription: {
    defaultMessage: 'Interval',
    description: '[Token] interval Token description',
    id: 'TOKENS.interval.description',
  },
  matchRuleEnrollmentSingleFieldLabel: {
    defaultMessage: 'Select the enrollment field',
    description: '[ProgramWizard] Match Rule enrollment single field label',
    id: 'program.wizard.match.rule.enrollment.single.field.label',
  },
  matchRuleProfileSingleFieldLabel: {
    defaultMessage: 'Select the profile field',
    description: '[ProgramWizard] Match Rule profile single field label',
    id: 'program.wizard.match.rule.profile.single.field.label',
  },
  matchRuleProfileMultipleFieldLabel: {
    defaultMessage: "Select the profile field you'd like to reference for the potential match",
    description: '[ProgramWizard] Match Rule profile multiple field label',
    id: 'program.wizard.match.rule.profile.multiple.field.label',
  },
  matchRuleProfileMultipleSubFieldLabel: {
    defaultMessage: "Select the profile field you'd like to compare for the participant",
    description: '[ProgramWizard] Match Rule profile multiple sub field label',
    id: 'program.wizard.match.rule.profile.multiple.sub.field.label',
  },
  matchRuleProfileMultipleFieldDescription: {
    defaultMessage:
      'E.g., If you\'re creating a rule to not allow matching with direct supervisor, you\'d select "Supervisor ID / Supervisor E-mail" or similar for this field.',
    description: '[ProgramWizard] Match Rule profile multiple field description',
    id: 'program.wizard.match.rule.profile.multiple.field.description',
  },
  matchRuleEnrollmentMultipleFieldLabel: {
    defaultMessage: "Select the enrollment field you'd like to reference for the potential match",
    description: '[ProgramWizard] Match Rule enrollment multiple field label',
    id: 'program.wizard.match.rule.profile.enrollment.field.label',
  },
  matchRuleEnrollmentMultipleSubFieldLabel: {
    defaultMessage: "Select the enrollment you'd like to compare for the participant",
    description: '[ProgramWizard] Match Rule enrollment multiple sub field label',
    id: 'program.wizard.match.rule.profile.enrollment.sub.field.label',
  },
  matchRuleYesNoNoPreferenceFieldLabel: {
    defaultMessage: 'Select the Yes/No/NoPreference Field',
    description: '[ProgramWizard] Match Rule YES/NO/NOPREF field label',
    id: 'program.wizard.match.rule.yes-no-nopref.field.label',
  },
  matchRuleYesNoNoPreferenceSubFieldLabel: {
    defaultMessage: 'Select the Yes/No/NoPreference Profile Field',
    description: '[ProgramWizard] Match Rule YES/NO/NOPREF sub field label',
    id: 'program.wizard.match.rule.yes-no-nopref.sub.field.label',
  },
  matchRuleTimeZoneFieldLabel: {
    defaultMessage: 'Select the Timezone Preference Field',
    description: '[ProgramWizard] Match Rule TimeZone field label',
    id: 'program.wizard.match.rule.timezone.field.label',
  },
  matchRuleTimeZoneSubFieldLabel: {
    defaultMessage: 'Select the Timezone Profile Field',
    description: '[ProgramWizard] Match Rule TimeZone sub field label',
    id: 'program.wizard.match.rule.timezone.sub.field.label',
  },
  learningLabQuickStartOnDemandLabel: {
    defaultMessage: 'Quick Start on Demand',
    description: '[LearningLab.LabQuickStartOnDemand] Quick Start on Demand label',
    id: 'learning.lab.quick.start.demand.label',
  },
  cliqAiLabel: {
    defaultMessage: 'CliQ AI',
    description: '[Global] CliQ AI label',
    id: 'global.qliq.ai.label',
  },
});
