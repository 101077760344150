import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQCardBodyProps {
  className?: string;
  inline?: boolean;
  children?: ReactNode;
  compact?: boolean;
  alt?: boolean;
  gap?: 'sm';
  scrollable?: boolean;
}

const MQCardBody: FC<MQCardBodyProps> = ({
  className = '',
  alt = false,
  compact = false,
  children,
  gap,
  scrollable,
}) => (
  <div
    data-testid="mq-card-body"
    className={classNames(['mq-card-body', gap, { alt }, className, { compact }, { scrollable }])}
  >
    {children}
  </div>
);

export default MQCardBody;
